<template>
  <div class="page-about">
    <div class="page-about__description" v-if="content">
      <div class="page-about__head">
        <h4>{{ content.subtitle }}</h4>
        <h1>{{ content.title }}</h1>
      </div>
      <div class="page-about__description-col" v-html="content.frames[0].about_frame_text"></div>
      <div class="page-about__description-col" v-html="content.frames[1].about_frame_text"></div>
    </div>
    <div class="about-team" v-if="content && content.people">
      <div v-for="(item, i) of content.people" :key="`person-${i}`" class="team-item">
        <img v-if="item.person_photo" :src="item.person_photo.sizes.large" />
        <div class="team-item-info">
          <span class="team__person-name">{{ item.person_firstname }}</span>
          <span class="team__person-name">{{ item.person_lastname }}</span>
          <span class="team__person-job">{{ item.person_job }}</span>
          <div class="team__person-links">
            <a :href="`mailto:${item.person_email}`" class="team__person-link">
              <iconContact />
              <span>{{ item.person_email }}</span>
            </a>
            <a :href="item.person_linkedin"
              class="team__person-link"
              target="_blank">
              <iconLinkedin />
              <span>Linkedin</span>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/_theme.scss";
@import "@/styles/Page__About.scss";
</style>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/staticPages'
import iconLinkedin from "@/assets/icon-linkedin.vue";
import iconContact from "@/assets/icon-contact.vue";

export default {
  components: {
    iconLinkedin,
    iconContact,
  },

  computed: {
    ...mapState({
      content: state => state.staticPages.contentPage
    }),
  },

  async mounted() {
    await this.$store.dispatch(
      `staticPages/${actionNames.LOAD_PAGE_CONTENT}`,
      '/about/'
    )
    this.$store.state.OnAir.btnWhite = false
  }
};
</script>
