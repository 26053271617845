import { fetchContent } from '../services/clients'

export const types = {
  SELECT_COMMIT: 'selectCommit',
  FETCH_COMMIT: 'fetchCommit',
  FETCH_SINGLE_COMMIT: 'fetchSingleCommit'
}

export const actionNames = {
  FETCH: 'fetch',
  FETCH_SINGLE: 'fetchSingle',
  LOAD_PAGE_CONTENT: 'loadPageContent'
}

export default {
  namespaced: true,
  state: {
    contentClients: null,
    contentSingleClients: null,
    cachedPages: []
  },

  mutations: {
    [types.FETCH_COMMIT](state, pageData) {
      state.contentClients = pageData
      const hasPage = state.cachedPages.find(x => x.id === pageData.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, pageData ]
    },

    [types.FETCH_SINGLE_COMMIT](state, pageData) {
      state.contentSingleClients = pageData
      const hasPage = state.cachedPages.find(x => x.id === pageData.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, pageData ]
    }
  },

  actions: {
    async [actionNames.FETCH]({ commit }, slug) {
      const contentData = await fetchContent(slug)
      commit(types.FETCH_COMMIT, contentData)
    },

    async [actionNames.FETCH_SINGLE]({ commit }, slug) {
      const contentData = await fetchContent(slug)
      commit(types.FETCH_SINGLE_COMMIT, contentData)
    },

    async [actionNames.LOAD_PAGE_CONTENT]({ state, commit }, slug) {
      const pageContent = state.cachedPages.find(x => x.slug === slug)
      const contentData = (!pageContent) ? await fetchContent(slug) : pageContent

      if (slug === '/clients/') {
        commit(types.FETCH_COMMIT, contentData)
      } else {
        commit(types.FETCH_SINGLE_COMMIT, contentData)
      }
    }
  }
}
