<template>
  <article class="section-inspirations__list-item-details">
    <router-link
        :to="`/inspirations/${item.url}/`"
        class="section-inspirations__list-item-title">
      <span>{{ item.title }}</span>
    </router-link>
    <router-link
        :to="`/inspirations/${item.url}/`"
        class="section-inspirations__list-item-intro">
      <span>{{ item.intro }}</span>
    </router-link>
    <router-link
        :to="`/inspirations/${item.url}/`"
        class="section-inspirations__list-item-cta btn__cta btn__cta-more-dark">
      <span>czytaj więcej</span>
      <iconArrow class="btn__cta-arrow" />
    </router-link>
  </article>
</template>

<script>
import iconArrow from '@/assets/icon-arrow.vue'

export default {
  components: {
    iconArrow
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
