import { fetchSettings, fetchFundusze } from '../services/settings'
import { fetchPage, fetchSlownik } from '../services/page'

export const types = {
  SELECT_COMMIT: 'selectCommit',
  FETCH_COMMIT: 'fetchCommit',
  FETCH_FUNDUSZE_COMMIT: 'fetchFunduszeCommit',
  FETCH_PAGE_COMMIT: 'fetchPageCommit',
  FETCH_SLOWNIK_COMMIT: 'fetchSlownikCommit',
}

export const actionNames = {
  FETCH_SITE_SETTINGS: 'fetch',
  FETCH_FUNDUSZE: 'fetchFundusze',
  FETCH_PAGE: 'fetchPage',
  FETCH_SLOWNIK: 'fetchSlownik',
}

export default {
  namespaced: true,
  state: {
    menu: [],
    socials: [],
    email: [],
    company: null,
    fundusze: [],
    privacy: null,
    page: null,
    slownik: null,
  },

  mutations: {
    [types.FETCH_COMMIT](state, settingsData) {
      state.menu = settingsData.site_menu
      state.socials = settingsData.site_social
      state.email = settingsData.site_email
      state.company = settingsData.site_company
    },

    [types.FETCH_FUNDUSZE_COMMIT](state, data) {
      state.fundusze = data
    },

    [types.FETCH_PAGE_COMMIT](state, data) {
      state.page = data
    },

    [types.FETCH_SLOWNIK_COMMIT](state, data) {
      state.slownik = data
    },
  },

  actions: {
    async [actionNames.FETCH_SITE_SETTINGS]({ commit }) {
      const settingsData = await fetchSettings()
      commit(types.FETCH_COMMIT, settingsData)
    },

    async [actionNames.FETCH_FUNDUSZE]({ commit }) {
      const data = await fetchFundusze()
      commit(types.FETCH_FUNDUSZE_COMMIT, data)
    },

    async [actionNames.FETCH_PAGE]({ commit }, slug) {
      const data = await fetchPage(slug)
      commit(types.FETCH_PAGE_COMMIT, data)
    },

    async [actionNames.FETCH_SLOWNIK]({ commit }, slug) {
      const data = await fetchSlownik(slug)
      commit(types.FETCH_SLOWNIK_COMMIT, data)
    },
  },
}
