<template>
  <div class="page-services"
      :class="{ 'page--loading': !contentServices }">

    <div class="page-services__content">
      <div class="page-services__list" v-if="content">
        <div class="page-services__list-item"
          v-for="item in contentServices.children"
          :key="item.id"
          :id="item.id"
          :class="{ 'is-active': (service && (item.id === service.id))}">

          <h2 class="page-services__list-item-title">
            <a :href="item.slug" @click="(e) => show(e, item)" class="page-services__list-item-link">
              <span>{{ item.title }}</span>
            </a>
          </h2>
          <div class="page-services__subitem"
            v-for="subitem in item.children"
            :key="subitem.id"
            :id="subitem.id"
            :class="{ 'is-active': (service && (subitem.id === service.id))}">

            <h3 class="page-services__subitem-title">
              <a :href="subitem.slug" @click="(e) => show(e, subitem)" class="page-services__subitem-link">
                <span>{{ subitem.title }}</span>
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="page__description">
      <h1 v-if="content"
        class="page__description-title"
        @click="() => service = null">{{ content.title }}</h1>
      <div class="service__description is-active">
        <h3 v-if="service">{{ service.title }}</h3>

        <div class="service__description-content" v-if="!service && content">
          <div class="service__description-content-txt">
            <div v-html="content.description_short"></div>
          </div>
          <img class="service__description-content-image" :src="content.image" :alt="content.title" />
        </div>

        <div class="service__description-text" v-if="service && service.description_short" v-html="service.description_short"></div>
        <div class="service__description-text" v-if="service && service.description_long" v-html="service.description_long"></div>

        <a v-if="service"
          :href="`mailto:kontakt@dpcpolska.pl?subject=DPC | ${service.title}`"
          class="btn__cta btn__cta-more-dark">
          <span>Zapytaj o ofertę</span>
          <iconArrow class="btn__cta-arrow" />
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/services'
import iconArrow from '@/assets/icon-arrow.vue'

export default {
  name: "ServicesPage",

  components: {
    iconArrow
  },

  data() {
    return {
      content: null,
      service: null,
    }
  },

  computed: {
    ...mapState({
      contentServices: state => state.services.contentServices
    }),
  },

  methods: {
    show(e, item) {
      e.preventDefault()
      this.service = item
      window.history.replaceState({page: item.id}, '', item.slug)
    }
  },

  async mounted() {
    if (!this.$store.state.services.contentServices) {
      await this.$store.dispatch(
        `services/${actionNames.LOAD_PAGE_CONTENT}`,
        '/services/'
      )
    }
    this.content = this.$store.state.services.contentServices
    if (this.$route.params.sub) {
      const parent = Object.values(this.content.children).find(x => x.slug === `/services/${this.$route.params.id}/`)
      this.service = Object.values(parent.children).find(x => x.slug === this.$route.path)
    } else {
      this.service = Object.values(this.content.children).find(x => x.slug === this.$route.path)
    }
    this.$store.state.OnAir.btnWhite = false
  }

}
</script>


<style lang="scss">
@import "@/styles/_theme.scss";

.page-services {
  padding: 17vh 0rem 13vh;
  min-height: 100vh;
  margin-bottom: 10vh;
  display: flex;
  position: relative;
  z-index: 3;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $bg-color;

  @media screen and (max-width: 768px) {
    padding: 10vh 0;
    flex-wrap: wrap;
  }

  &::after {
    content: " ";
    position: fixed;
    width: 100%;
    height: 1.5rem;
    bottom: 0;
    z-index: 9999;
    background: $white;
    display: block;
    left: 0;
    transition: $transition-fast;
  }

  &__content {
    flex: 1 1 auto;
    max-width: 45%;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__list {
    margin-left: 2rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    transition: $transition;

    @media screen and (max-width: 768px) {
      margin: 0;
      max-width: 100%;
    }

    h4 {
      padding: 0px;
      margin: 0;
      font-family: $font-header;
      font-size: 1rem;
      display: flex;
      white-space: nowrap;
      height: 2rem;
      align-items: center;
      color: $pencil;
      font-weight: normal;
    }

    &-item {
      display: flex;
      flex-direction: column;
      color: $pencil;
      padding: 0;
      position: relative;
      transition: $transition;
      cursor: pointer;

      span {
        transition: $transition;
      }

      &::after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 1rem;
        left: 0.5rem;
        background: $black-transparent;
        z-index: 1;
      }

      &.is-active {
        h2 {
          padding-left: 3rem;

          &::before {
            width: 2rem;
            background-color: $pencil-diff;
          }
          span {
            color: $pencil-color;
          }
        }
      }

      &-title {
        width: 100%;
        text-transform: none;
        font-size: 1.625rem;
        padding: 0.25rem 0;
        margin: 0.5rem 0;
        font-family: $font-bold;
        font-weight: normal;
        text-align: left;
        position: relative;
        z-index: 9;
        display: block;
        transition: $transition;
        background-color: $bg-color;

        &::before {
          content: "";
          display: block;
          width: 0rem;
          height: 2px;
          background: $black-transparent;
          position: absolute;
          left: 0.5rem;
          top: 50%;
          transition: $transition;
        }

        &:hover {
          padding-left: 3rem;

          &::before {
            width: 2rem;
            background-color: $pencil-diff;
          }
          span {
            color: $pencil-color;
          }
        }
      }

      &-link {
        color: $pencil;
      }
    }
  }

  &__subitem {
    &-title {
      margin: 0 0 0 2rem;
      padding: 0.25rem 0;
    }
    &-link {
      color: $pencil;
      font-size: 1rem;
      font-family: $font-header;
      transition: $transition;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 0rem;
        height: 1px;
        background-color: $pencil-color;
        position: absolute;
        left: 0;
        top: 50%;
        transition: $transition;
      }
    }

    &.is-active,
    &:hover {
      .page-services__subitem-link {
        color: $pencil-color;
        padding-left: 3rem;

         &::before {
          width: 2rem;
        }
      }
    }
  }

.page__description {
  flex: 1;
  max-width: 48%;
  margin-left: 2%;
  transition: $transition;
  position: sticky;
  top: 5rem;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 5rem 1rem 2rem;
  }

  &.is-active {
    max-width: 48%;
  }

  &-title {
    width: 100%;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil-color;
    margin: 0 0 1.5rem;
    padding: 0.5rem 0 2rem;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
      padding: 0 0 1.5rem;
    }

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: $spacer-width;
      height: $spacer-height;
      background: $pencil-color;
      transition: $transition-fast;
    }

    &:hover::before {
      width: 4rem;
    }
  }
}

.service__description {
  padding: 3rem;
  background: $white;
  max-width: 100%;
  transition: $transition;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }

  &::before {
    content: " ";
    position: absolute;
    bottom: -2.5rem;
    right: -2rem;
    width: 50%;
    height: 80%;
    max-height: 50vh;
    z-index: -1;
    background-color: $pencil-color;
  }

  h3 {
    font-size: 1.287rem;
    font-family: $font-bold;
    font-weight: 400;
    padding: 0 1rem 1rem 0;
    margin: 0;
    border-bottom: 1px solid $black-transparent;
    color: $pencil-color;
    text-transform: uppercase;

    &:before {
      display: none;
    }
  }

  ul {
    list-style-type: square;
    margin: 0;
    padding: 1rem 3rem 0rem;

    @media screen and (max-width: 768px) {
      padding: 1rem 2rem 0;
    }
  }

  li {
    padding: 0 0 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  &-content {
    padding: 0;
    margin: -3rem;
    width: calc(100% + 6rem);
    position: relative;
    display: block;

    @media screen and (max-width: 768px) {
      margin: 0rem -1.5rem -1.5rem 0;
      width: calc(100% + 1.5rem);
    }

    &-image {
      width: 100%;
    }

    &-txt {
      position: absolute;
      display: flex;
      bottom: 9%;
      right: 6%;
      width: 80%;
      padding: 2rem;
      color: $white;
      font-family: $font-family;
      font-size: 1rem;
      line-height: 1.5;

      @media screen and (max-width: 768px) {
        position: relative;
        width: 100%;
        right: auto;
        bottom: auto;
      }

      p {
        margin: 0;

        + p {
          margin-top: 0.75rem;
        }
      }

      > div {
        position: relative;
        z-index: 2;
      }

      &:before {
        content: "";
        background: $pencil-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.85;
      }
    }
  }

  &-text {
    font-size: 1rem;
    line-height: 1.7;
    color: $pencil;

    strong {
      text-transform: uppercase;
      font-family: $font-bold;
      font-weight: 400;
    }

    p {
      margin: 0;
      padding: 1rem 0;
    }
  }



  .btn__cta {
    align-self: flex-start;
    margin-top: 2rem;
  }
}

}
</style>
