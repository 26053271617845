<template>
  <div class="page-single">

    <Fundusze v-if="slug === '/fundusze-europejskie/' && fundusze && fundusze.content" :fundusze="fundusze" />
    <div v-else-if="slug === '/privacy-policy/' && page">
       <div v-if="page.content" v-html="page.content" />
    </div>

    <Slownik v-else-if="slug === '/slownik-lean/' && slownik" :slownik="slownik" />

  </div>
</template>

<style lang="scss">
@import "@/styles/_theme.scss";
</style>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/attributes'
import Fundusze from '@/components/Fundusze'
import Slownik from '@/components/Slownik'

export default {
  name: 'SinglePage',

  props: {
    slug: {
      type: String,
      default: null,
    }
  },

  components: {
    Fundusze,
    Slownik,
  },

  computed: {
    ...mapState({
      fundusze: state => state.attributes.fundusze,
      page: state => state.attributes.page,
      slownik: state => state.attributes.slownik,
    }),
  },

  methods: {
    fetchPage: function(slug) {
      if (slug === '/slownik-lean/') {
        this.$store.dispatch(`attributes/${actionNames.FETCH_SLOWNIK}`, slug)
      } else {
        this.$store.dispatch(`attributes/${actionNames.FETCH_PAGE}`, slug)
      }
    }
  },

  watch: {
    slug: function(val) { this.fetchPage(val) }
  },

  mounted() {
    this.fetchPage(this.slug)
  }
};
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.page-single {
	padding: 9rem 6rem 5rem 4rem;
	display: flex;
  position: relative;
  z-index: 3;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  line-height: 1.7;

  @media screen and (max-width: 768px) {
    padding: 4rem 1rem;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  &__head {
    flex: 1 0 100%;
    width: 100%;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil;
    margin: 0 0 1.5rem;
    padding: 0rem 0 2rem;
    position: relative;
    text-transform: uppercase;
    line-height: 1.2;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: $spacer-width;
      height: $spacer-height;
      background: $pencil;
    }
  }

  &__content {
    flex: 1;
    max-width: calc(100% - 10rem);
    padding-right: 8rem;
    text-align: justify;

    @media screen and (max-width: 768px) {
      flex: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  &__raport {
    flex: 0;
    min-width: 10rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex: 100%;
      max-width: 100%;
      padding: 0;
    }

    &-pdf {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $bg-light;
      padding: 1.5rem 1rem;
      transition: $transition-fast;

      &:hover {
        background-color: $bg-alt;
      }


      span {
        color: $pencil;
        font-family: $font-bold;
        display: block;
        margin: 1rem 0 0;
        text-transform: uppercase;
        text-align: center;
      }

      svg {
        height: 7rem;
      }
    }
  }
}
</style>
