export default [
   {
      id: 'programy-rozwojowe',
      title: 'Programy rozwojowe',
      fulldesc: 'Jedną z najważniejszych ról liderów jest rozwijanie swoich pracowników.<br/> Pozyskiwanie nowych umiejętności, kompetencji oraz wiedzy to fundament budowania kultury uczenia się.<br/><br/> W organizacjach wysokoefektywnych rozwój pracowników to proces, którego efektem jest motywowanie procowników do wdrażania nowych pomysłów poprzez kwestionowanie obecnego statusu oraz eksperymentowanie.<br/> W DPC tworzymy indywidualne programy rozwojowe z myśla o "uzbrajaniu" pracowników, lierów* w umiejętności, kompetencje i narzędzia, które w pozwolą im zwiększać efektywność swoich organizacji.',
      elements: [
         {
            title: 'Lider Doskonałości Operacyjnej',
            desc: 'Program dedykowany dla poziomu średniej i niskiej kadry zarządzającej (Managerowie, Kierownicy. Liderzy, Mistrzowie, Koordynatorzy, Brygadziści), jego celem jest uzbrojenie uczestników w najlepsze standardy zarządzania, które pozwalają na codzienne efektywne zarządzanie zespołami i procesami. Ważnym elementem programu jest nabywanie przez uczestników umiejętności i kompetencji zarówno miękkich jak i twardych. Liderzy uczą się jak budować atnosferę w zespole, jak zarządzać czasem swoim i podległego zespołu, nabywają umiejętności jak wdrażać proces rozwoju pracowników, jak skutczenie doskonalić procesy jak budować system wskaźników w organizacji. Te i wiele innych umiejętności nowoczesnego zarządzania pozwolą adeptom tego programu znacząco zwiększyć skuteczność swojego działania w codziennym zarządzaniu.',
         },
         {
            title: 'Gry symulacyjne',
            desc: '',
            list: [
               {
                  title: 'Lean Challenge Cup',
                  desc: 'Gra symulacyjna organizowana w formule zamkniętej dla firm pragnących zwiększyć świadomość i umiejętności wśród swoich pracowników jak skutecznie doskonalić procesy używając do tego metodyki Lean. Taki format warsztatów świetnie angażuje uczestników i pozwala natychmiastowo za pośrednictwem gry symulacyjnej przekłądać zdobytą wiedzę na umiejętności doskonalenia procesów. Uczstnicy podczas jedodniowego warsztatu mają możliwość zgłębić wiedzę w jaki sposób zwiększać efektywność procesów oraz tworzyć środowisko sprzyjające budowaniu kultury ciągłego doskonalenia.'
               },
               {
                  title: 'Lean Office Game',
                  desc: 'Warsztaty szkoleniowe w formie gry symulacyjnej dedykowane głównie dla osób zaangazowanych w zarządzanie i realizację procesów administracyjno-biurowych. Gra idalnie nadaj się do środowiska z sektorów BPO/SS/GBS oraz firm, które chcą rozpoczynać budowanie kultury Lean – kultury ciągłego doskonalenia w procesach finansowo-administracyjnych. Uczestnicy gry oprócz solidej dawki wiedzy mają możliwość przenoszenia nowe kompetencji na doskonalenia zasymulowanego procesu – wystawianie faktur. Uczestnicy podzieleni na zespoły rywalizują, poprzez modelowanie nowego procesu co sprawia, że ich zaangazoanie i zdobyta wiedza stają się kluczowymi aspektami, aby udoskonalić proces i zwiększość jego efektywność. Budowanie świadomości potrzeby doskonalenia, podstawowe narzędzia pozwalające zwiększać efektywność procesów, wiedza niezbędna do przeprowadzania organizacji przez zmianę to tylko niektóre elementy poruszane podczas szkolenia.'
               }
            ]
         },
         {
            title: 'Programy Certyfikujące',
            desc: 'W DPC stworzyliśmy 3 Programy Certyfikacyjne, które dedykowane są do różnych poziomów zaawansowania adeptów Lean. Opracowując te programy największy nacisk położyliśmy na to w jaki sposób zdobywana jest wiedza i jak przebiega procesu uczenia się. Wyznajemy zasadę "Learning by doing" dlatego większość spotkań w ramach realizowanych programów odbywa się na terenie firm partnerskich, pozwala to uczestnikom zdobywać wiedzę w podczas pracy w GEMBA (mieście w którym realizowany jest proces). Zajęcia prowadzone są przez praktyrków z wieloletnim doświadczeniem, którzy w odpowiednim środowisku (GEMBA), dbają o to aby uczestnicy przechodzili przez proces uczenia się w odpowiednim tempie i jakości.',
            list: [
               {
                  title: 'Lean Expert',
                  desc: 'Program dedykowany dla osób rozpoczynających swoją przygodę z doskonaleniem procesów i wdrażaniem Strategii Lean w organizacjach. Program ten ma na celu zbudowanie podstawowej i niezbędnej wiedzy do tego, aby skutecznie rozpocząć swoją przygodę z Lean Management.'
               },
               {
                  title: 'Lean Strategy Green Belt',
                  desc: 'Pogram dedykowany dla tych osób, które miały już do czynienia z metodyką Lean, znają jej podstawowe narzędzia i chcą zacząć drogę we wdrażaniu strategicznym Lean. Dużą wagą w tym programie przykładamy do wykorzystywania właściwych narzędzi i umiejętności przeprowadzania transformacji całych organizacji w kierunku Lean.'
               },
               {
                  title: 'Lean Strategy Black Belt',
                  desc: 'Program dedykowany dla osób zaawansowanych, na co dzień zajmujących się wdrażaniem Lean w organizacjach, w których pracują. Posiadjących doświadczenie i znajomość technik Lean. W tym programie duzą wagę przykłądamy to tworzenia modelu doskonalenia organizacji i tworzenia systemu zarządzania dla całej organizacji.'
               }
            ]
         },
         {
            title: 'Studia Podyplomowe',
            desc: '',
            list: [
               {
                  title: 'Akademia Lean Lidera - WSB Toruń',
                  desc: 'Kierunek, którego jesteśmy patronem na Podyplomowych Studiach w Wyższej Szkole Bankowej w Toruniu. Bogaty program oraz praktyczny charakter warsztatów to niewątpliwie duża zachęta dla osób chcących poszerzać swoje doświadczenia w zakresie Strategii Lean. Oprócz sal wykładowych Torunskiej uczelni, zajęcia prowadzone są również na terenie firm partnerskich co daje możliwości wypróbowania zdobytej wiedzy w realnych procesach. Przyświeca to również idei "Learning by doing" i temu, że GEMBA* jest najlepszym nauczycielem Lean. Uczestnicy po zakończeniu studiów oczywiście otrzymują Dyplom, mają tez możliwość w trakcie realizacji Studiów Certyfikowania się w Programie "Lean Strategy Green Belt". Zapraszamy wszystkich zainteresowanych do kontaktu z uczelnią i zapisywaniu się na kolejny rok.'
               }
            ]
         }
      ]
   },
   {
      id: 'analizy-i-diagnozy',
      title: 'Analizy i diagnozy',
      fulldesc: 'Podejmowanie decyzji to jeden z najważniejszych procesów, jakie zachodzą w organizacjach. Najlepiej podejmować je w oparciu o dane i fakty, a nie w oparciu o opinie i poglądy innych osób. Pozyskiwanie informacji zarządczej jest zatem ważnym elementem działania każdej organizacji, a w szczególności osób zarządzających. <br/><br/>W DPC wierzymy, że logika działań a w konsekwencji ich efekt jest w dużym stopniu zależna od informacji, które posiadamy i oparciu o które podejmujemy decyzje. Na codzie współpracując z naszymi klientami, przeprowadzamy szereg analiz, które pomagają podejmować właściwe decyzje i pokazywać potencjał do rozwoju organizacji.',
      elements: [
         {
            title: 'Ocena potencjału',
            desc: 'Ocena potencjału to diagnoza efektywności realizowanych procesów, której efektem jest precyzyjne określenie potencjału do zwiększenia efektywności. Tego typu działanie przeprowadzane jest w 3 etapach: Transfer Wiedzy – Zbieranie Danych – Wnioski i Rekomendacje. Przejście przez taki proces, zapewnia organizacji rozpoczęcie budowania kultury organizacyjnej. Pracownicy organizacji zdobywają praktyczną umiejętność jak badać i analizować procesy, zbieranie danych empirycznych pozwala, realnie ocenić na ile efektywne są procesy, które badamy i jaki jest potencjał do ich usprawniania. Po przeprowadzeniu pomiarów, bada i zebraniu informacji, pracownicy firmy wraz z osobami prowadzącymi przystępują do opracowania wniosków i rekomendacji, które są impulsem do działa rozwojowych.',
         },
         {
            title: 'Helicopter View',
            desc: 'Helicopter View to szybka najczęściej zajmująca od 1 do 3 dni analiza, która ma na celu wstępnie określenie czy w badanych procesach, obszarach istnieje potencjał do rozwoju.',
         },
         {
            title: 'Analiza struktury organizacyjnej',
            desc: 'Struktura organizacyjna określa podział prac i odpowiedzialności w organizacji oraz wskazuje powiązania między różnymi funkcjami w firmie. Analiza obecnej struktury firmy jest niezwykle istotna – pomaga dostosować zarządzanie do zmian w procesach (np. produkcyjnym), zmian osobowych czy też zmian regulacyjnych. Badanie może dotyczyć np. struktury organizacyjnej w obszarze produkcji lub całej firmy.',
         },
         {
            title: 'Analiza wskaźników',
            desc: 'Dynamiczne otoczenie, zmieniające się przepisy, presja na nieustanne obniżanie kosztów operacyjnych oraz rozwój konkurencji znacznie utrudniają funkcjonowanie przedsiębiorstw na rynku. Działając w takim środowisku, niezbędna jest częsta analiza wskaźników Twojej firmy i stałe monitorowanie jej sytuacji majątkowo-finansowej, tak aby móc właściwie ukierunkować kolejne podejmowane działania strategiczne.<br/><br/>Odpowiedzią na to wyzwanie jest audyt wskaźników Twojej firmy. Wspólnie z kadrą zarządzającą analizujemy wszystkie wskaźniki, które są monitorowane, przeprowadzamy wywiady, udzielamy rekomendacji. Mapujemy wskaźniki na strukturę organizacyjną, identyfikujemy powiązania między obszarami oraz budujemy drzewo wskaźników (np. rentowność, efektywność sprzedaży, produktywność, rotacja, zysk itp.) na różnych poziomach organizacji.',
         },
         {
            title: 'Analiza layoutu hali produkcyjnych',
            desc: 'Podstawą dobrze funkcjonującej fabryki jest ustawienie maszyn i urządzeń (layout), optymalnie zaprojektowane ciągi komunikacyjne oraz rozmieszczenie magazynów. To połączone „naczynia”, które stanowią o efektywności procesu wytwarzania.<br/><br/>Tworząc koncepcję przepływu materiałów oraz projektując ustawienia linii produkcyjnych, korzystamy z zasad „Flow management”. Naszym priorytetem jest zwiększenie przepustowości fabryki, optymalny czasu produkcji, transportu oraz zwiększenie dostępnego miejsca.',
         },
         {
            title: 'Analiza poziomu wdrożenia lean w organizacji',
            desc: 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas malesuada elit lectus felis, malesuada ultricies. Curabitur et ligula. Ut molestie a, ultricies porta urna. Vestibulum commodo volutpat a, convallis ac, laoreet enim.',
         },
      ]
   },
   {
      id: 'strategia-lean',
      title: 'Strategia Lean',
      fulldesc: 'W DPC tworzymy indywidualne programy rozwojowe z myśla o "uzbrajaniu" pracowników, lierów* w umiejętności, kompetencje i narzędzia, które w pozwolą im zwiększać efektywność swoich organizacji. Celem naszego działania jest zbudowanie systemu zarządzania, dzięki któremu nasi klienci będą skutecznie: realizować swoje cele, budować przewagę konkurencyjną a w konsekwencji rozwijać organizację.'
   },
   {
      id: 'system-zarzadzania-efektywnoscia',
      title: 'System zarządzania efektywnością',
      fulldesc: 'Nasze podejście opieramy na wieloletnim doświadczeniu, nie jest ono szablonowe i zazwyczaj indywidualne dla każdego klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.'
   },
   {
      id: 'warsztaty-doskonalenia',
      title: 'Warsztaty doskonalenia',
      fulldesc: 'Jeśli organizacja mogłaby wybrać tylko jedną metodę szybkiego odblokowania wartości, jakie podejście powinno znaleźć się na szczycie listy? Jeśli firma mogłaby zastosować tylko jedną metodę kształtowania swojej kultury w celu ciągłego doskonalenia, jakiego narzędzia powinna użyć? Oczywiście Warsztatów Doskonalenia - 5 dniowych intensywnych warsztatów wdrożeniowych, podczas których zespół identyfikuje, określa i wprowadza usprawnienia w procesie.'
    },
    {
      id: 'struktury-organizacyjne',
      title: 'Struktury organizacyjne',
      fulldesc: 'Struktura organizacyjna określa podział prac i odpowiedzialności w organizacji oraz wskazuje powiązania między różnymi funkcjami w organizacji. Analiza obecnej struktury firmy jest niezwykle istotna – pomaga dostosować zarządzanie do zmian w procesach (np. produkcyjnym), zmian osobowych czy też zmian regulacyjnych.',
      elements: [
         {
            title: 'Nasze narzędzia',
            desc: '',
            list: [
               { 
                  title: 'Ankieta badawcza',
                  desc: ''
               },
               { 
                  title: 'Analiza dokumentacji',
                  desc: ''
               },
               { 
                  title: 'Weryfikacja poziomów zarządzania i rozpiętości zarządzania',
                  desc: ''
               },
               { 
                  title: 'Analiza systemu wytwarzania i poziomu jakości',
                  desc: ''
               }
            ]
         }
      ]
    },
    {
      id: 'robotic-process-automation',
      slug: '/services/robotic-process-automation',
      title: 'Robotic Process Automation',
      fulldesc: 'Jeśli Twoje procesy są już po standaryzacji i optymalizacji czas na kolejny krok – ich automatyzację! Rozumiejąc obecne wyzwania biznesowe wspieramy w budowaniu przewagi konkurencyjnej także poprzez automatyzację i robotyzację procesów.',
      img: 'services-rpa',
      elements: [
         {
            title: 'Nasze kompetencje',
            desc: '',
            list: [
               { 
                  title: 'Technologia',
                  desc: 'Projektujemy i programujemy zaawansowane oraz nowoczesne rozwiązania realizujące wyzwania stawiane przed robotami.  Spełniamy najwyższe standardy w zakresie architektury i bezpieczeństwa.'
               },
               { 
                  title: 'Zarządzanie projektami',
                  desc: 'Pomagamy osiągnąć cele w zwinny i efektywny sposób. Przeprowadzamy automatyzację i robotyzację od momentu planowania do wdrożenia rozwiązania.'
               },
               { 
                  title: 'Model zarządzania',
                  desc: 'Budujemy model współpracy dostosowany do potrzeb organizacji oraz wdrażanego rozwiązania.'
               },
               { 
                  title: 'Standaryzacja procesów',
                  desc: 'Przygotujemy procesy do automatyzacji i robotyzacji. Przeprowadzamy przez proces standaryzacji na potrzeby wdrożenia i definiujemy wymagania oraz reguły dla robotów.'
               }
            ]
         },
         {
            title: 'Nasze usługi i ich rezultaty',
            desc: '',
            list: [
               {
                  title: 'Analiza biznesowo-techniczna',
                  desc: '',
                  items: [
                     'Lista procesów do robotyzacji',
                     'Określenie potencjalnych korzyści',
                     'Techniczna analiza procesu',
                     'Ocena poziomu gotowości procesu do robotyzacji'
                  ]
               },
               {
                  title: 'Roboty procesowe',
                  desc: '',
                  items: [
                     'Automatyzacja czynności manualnych',
                     'Redukcja pracochłonności',
                     'Skrócenie czasu trwania procesu',
                     'Uzyskanie powtarzalności procesu'
                  ]
               },
               {
                  title: 'Zarządzanie robotami',
                  desc: '',
                  items: [
                     'Stabilnie pracujące roboty',
                     'Podążanie za zmianami w procesach',
                     'Zapewnienie ciągłości operacyjnej',
                     'Rekomendacje dotyczące doskonalenia procesu'
                  ]
               },
               {
                  title: 'Szkolenia',
                  desc: '',
                  items: [
                     'Wiedza pozwalająca na identyfikowanie obszarów do robotyzacji',
                     'Umiejętność oceny potencjału do automatyzacji',
                     'Zapewnienie ciągłości operacyjnej',
                     'Uzyskiwanie samodzielności w zakresie tworzenia i monitorowania robotów'
                  ]
               },
            ]
         }
      ]
    }
]
