<template>
  <button :disabled="disabled" class="pagination-cont__button">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
