export default {
  'intro-title' : {
    onAir: 0,
    delay: 10,
    speed: 200,
    title: 'Firmy tak skutecznie realizują swoje cele strategiczne, jak efektywne są ich procesy.',
    titles: [
      { id: 'intro-title-1', title: 'Firmy tak sktuecznie realizują', onAir: false },
      { id: 'intro-title-2', title: 'swoje cele strategiczne,', onAir: false },
      { id: 'intro-title-3', title: 'jak efektywne są ich procesy.', onAir: false },
    ],
    welcome: 'Wierzymy, że firmy są w stanie tak szybko się rozwijać, jak szybko  potrafią się uczyć. Pracownicy kwestionując status quo wpływają na rozwój swój jak i przedsiębiorstwa w którym pracują.'
  },
  'intro-claim' : {
    onAir: 0,
    delay: 3000,
    speed: 0.3,  // 0...1
    claims: [
      {
        id: 'intro-claim-subhead',
        title: 'Poznaj naszą misję',
        onAir: false,
        isPrev: false,
        class: 'intro-claims-subhead'
      },
      {
        id: 'intro-claim-head',
        title: 'Efektywne procesy uczenia się',
        onAir: false,
        isPrev: false,
        class: 'intro-claims-head'
      },
      {
        id: 'intro-claim-1',
        title: 'Skuteczność osiągania celów strategicznych zależy od efektywności procesów. Dlatego pomagamy pracownikom naszych klientów w kwestionowaniu status quo, przez co wpływają na rozwój swój jak i przedsiębiorstwa w którym pracują.',
        onAir: false,
        isPrev: false,
        class: 'intro-claims-txt'
      },
      {
        id: 'intro-claim-2',
        title: 'Wspieramy naszych Klientów w budowie systemów zarządzania, dzięki którym będą skutecznie: rozwijać organizację, budować przewagę konkurencyjną a w konsekwencji realizować swoje cele.',
        onAir: false,
        isPrev: false,
        class: 'intro-claims-txt'
      },
      {
        id: 'intro-claim-3',
        title: 'Nasze wieloletnie doświadczenie pozwala na indywidualne podejście do każdego Klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.',
        onAir: false,
        isPrev: false,
        class: 'intro-claims-txt'
      }
    ]
  }
}
