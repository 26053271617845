export default {
  'content' : {
    subtitle: 'Klienci i kompetencje w sektorach biznesowych',
    title: 'Zaufali nam',
    description: 'Celem naszego działania jest zbudowanie systemu zarządzania, dzięki któremu nasi klienci będą skutecznie: realizować swoje cele, budować przewagę konkurencyjną a w konsekwencji rozwijać organizację.<br/><br/>Nasze podejście opieramy na wieloletnim doświadczeniu, nie jest ono szablonowe i zazwyczaj indywidualne dla każdego klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.',
  },
  'list': [
      {
        id: 'sektor-kolejowy',
        link: '/clients/sektor-kolejowy',
        title: 'Sektor kolejowy',
        sector: 'Produkcja leków',
        location: 'Leverkusen',
        employees: '5820',
        problem: '&nbsp;',
        action: '&nbsp;',
        effects: '&nbsp;',
        effectsList: [],
        testimonial: {
          person: '',
          company: '',
          message: ''
        }
      },
      {
        id: 'branza-hutnicza',
        link: '/clients/branza-hutnicza',
        title: 'Branża hutnicza',
      },
      {
        id: 'farmacja',
        link: '/clients/farmacja',
        title: 'Farmacja',
        sector: 'Produkcja leków',
        location: 'Leverkusen',
        employees: '5820',
        problem: 'Firma w trakcie przekształcenia z SSC (Centrum Usług Wspólnych) na BPO, stanęła przed głównym wyzwaniem "zderzenia się" z rynkiem wysokokonkurencyjnym. Czego wynikiem była potrzeba transformacji organizacji, zwiększenia efektywności procesów i zbudowania nowych procesów wcześniej nie realizowanych. Ważnym elementem transformacji organizacji było zbudowanie skutecznego systemu zarządzania oraz zaangażowanie wszystkich poziomów organizacji w proces doskonalenia.',
        action: 'Potrzeba efektywnych procesów (Lean), polityki "zero  marnotrawstwa", doskonałej obsługi klienta i zdolności do zapewnienia wartości dodanej dla klienta zaowocowała dużym zaangażowaniem pracowników na każdym poziomie firmy.<br/><br/>Pierwszym krokiem naszych działa były Warsztaty Strategiczne przeprowadzone z Top Managementem. Efektem tego etapu było opracowanie wskaźników mierzących strategię i rozwój firmy, określenie głównych wyzwań oraz zaprojektowanie systemu zarządzania. <br/><br/>Działania Lean rozpoczęliśmy od dobrej zabawy podczas Gry Symulacyjnej Lean, w Warsztatach tych uczestniczyli pracownicy z każdego szczebla organizacji i był to świetny moment, aby wyrównać poziom wiedzy oraz rozpocząć budowanie świadomości i umiejętności skutecznego wdrażania Lean. Cały program nazwaliśmy "Lean Light".<br/><br/>Krok 3 naszego procesu to kaskada KPIs na niżesz poziomy organizacji co zaowocowało świadomością na każdym poziomie, jaką kto ma "cegiełkę" dołożyć, aby opracowana strategia miała szanse zadziałać.<br/><br/>Kolejnym krokiem było mapowanie procesów, dokładnie określiliśmy jaki jest potencjał do rozwoju. Efektem prac tego etapu był dokładny plan wdrożenia, który skrupulatnie wdrażaliśmy. Kluczowym elementem wdrożenia było zaangażowanie najwyższej kadry zarządzającej, co nadawała tempa prac i budowało zaangażowanie szeregowych pracowników.',
        effect: 'Konsekwentnie realizowana strategia rozwoju, budowa kultury ciągłego doskonalenia, skrócenie czasu realizacji procesów, znacząca poprawa efektywności realizowanych procesów, to tylko niektóre z efektów. Dzięki poprawie konkurencyjności, w konsekwencji firma pozyskała nowych klientów, co przyczyniło się do jej szybkiego rozwoju. Nowy system zarządzania stał się codziennie realizowanym standardem w obszarze: zarządzania, doskonalenia i procesów.',
        effectsList: [
          {
            id: 1,
            label: 'Skrócenie Lead Time',
            value: '-<strong>35</strong><small>%</small>'
          },
          {
            id: 2,
            label: 'Poprawa efektywności',
            value: '+<strong>17</strong><small>%</small>'
          },
          {
            id: 3,
            label: 'Pracowników organizacji uważa wdrożenie Lean za wartościowe',
            value: '<strong>93</strong><small>%</small>'
          }
        ],
        testimonial: {
          person: 'Dr Phillipe Laux',
          company: 'Bayer',
          message: 'SSC/BPO to rynek wysoko-konkurencyjny, jak radzić sobie z obecnymi wyzwaniami sektora opowie dyrektor zarządzający Frista BPO, organizacji która całkiem niedawno przekształciła się z SSC w BPO i skutecznie rozwija swoją działalność wykorzystując do tego Strategię Lean.'
        }
      },
      {
        id: 'bpo-business-process-outsourcing',
        link: '/clients/bpo-business-process-outsourcing',
        title: 'Business Process Outsourcing',
        sector: 'BPO',
        location: 'Warszawa',
        employees: '160',
        problem: 'Firma w trakcie przekształcenia z SSC (Centrum Usług Wspólnych) na BPO, stanęła przed głównym wyzwaniem "zderzenia się" z rynkiem wysokokonkurencyjnym. Czego wynikiem była potrzeba transformacji organizacji, zwiększenia efektywności procesów i zbudowania nowych procesów wcześniej nie realizowanych. Ważnym elementem transformacji organizacji było zbudowanie skutecznego systemu zarządzania oraz zaangażowanie wszystkich poziomów organizacji w proces doskonalenia.',
        action: 'Potrzeba efektywnych procesów (Lean), polityki "zero  marnotrawstwa", doskonałej obsługi klienta i zdolności do zapewnienia wartości dodanej dla klienta zaowocowała dużym zaangażowaniem pracowników na każdym poziomie firmy.<br/><br/>Pierwszym krokiem naszych działa były Warsztaty Strategiczne przeprowadzone z Top Managementem. Efektem tego etapu było opracowanie wskaźników mierzących strategię i rozwój firmy, określenie głównych wyzwań oraz zaprojektowanie systemu zarządzania. <br/><br/>Działania Lean rozpoczęliśmy od dobrej zabawy podczas Gry Symulacyjnej Lean, w Warsztatach tych uczestniczyli pracownicy z każdego szczebla organizacji i był to świetny moment, aby wyrównać poziom wiedzy oraz rozpocząć budowanie świadomości i umiejętności skutecznego wdrażania Lean. Cały program nazwaliśmy "Lean Light".<br/><br/>Krok 3 naszego procesu to kaskada KPIs na niżesz poziomy organizacji co zaowocowało świadomością na każdym poziomie, jaką kto ma "cegiełkę" dołożyć, aby opracowana strategia miała szanse zadziałać.<br/><br/>Kolejnym krokiem było mapowanie procesów, dokładnie określiliśmy jaki jest potencjał do rozwoju. Efektem prac tego etapu był dokładny plan wdrożenia, który skrupulatnie wdrażaliśmy. Kluczowym elementem wdrożenia było zaangażowanie najwyższej kadry zarządzającej, co nadawała tempa prac i budowało zaangażowanie szeregowych pracowników.',
        effect: 'Konsekwentnie realizowana strategia rozwoju, budowa kultury ciągłego doskonalenia, skrócenie czasu realizacji procesów, znacząca poprawa efektywności realizowanych procesów, to tylko niektóre z efektów. Dzięki poprawie konkurencyjności, w konsekwencji firma pozyskała nowych klientów, co przyczyniło się do jej szybkiego rozwoju. Nowy system zarządzania stał się codziennie realizowanym standardem w obszarze: zarządzania, doskonalenia i procesów.',
        effectsList: [
          {
            id: 1,
            label: 'Skrócenie Lead Time',
            value: '-<strong>35</strong><small>%</small>'
          },
          {
            id: 2,
            label: 'Poprawa efektywności',
            value: '+<strong>17</strong><small>%</small>'
          },
          {
            id: 3,
            label: 'Pracowników organizacji uważa wdrożenie Lean za wartościowe',
            value: '<strong>93</strong><small>%</small>'
          }
        ],
        testimonial: {
          person: 'Szymon Stadnik',
          company: 'Frista',
          message: 'SSC/BPO to rynek wysoko-konkurencyjny, jak radzić sobie z obecnymi wyzwaniami sektora opowie dyrektor zarządzający Frista BPO, organizacji która całkiem niedawno przekształciła się z SSC w BPO i skutecznie rozwija swoją działalność wykorzystując do tego Strategię Lean.'
        }
      },
      {
        id: 'sektor-bankowy',
        link: '/clients/sektor-bankowy',
        title: 'Sektor bankowy',
      },
      {
        id: 'logistyka',
        link: '/clients/logistyka',
        title: 'Logistyka',
      }      
  ],
}
