import Vue from 'vue'
import Router from 'vue-router'
import FrontPage from './views/FrontPage.vue'
import AboutPage from './views/AboutPage.vue'
import ContactPage from './views/ContactPage.vue'
import InspirationsPage from './views/InspirationsPage.vue'
import InspirationsSingle from './views/InspirationsSingle.vue'
import ServicesPage from './views/ServicesPage.vue'
// import ServicesSingle from './views/ServicesSingle.vue'
import ClientsPage from './views/ClientsPage.vue'
import ClientsSingle from './views/ClientsSingle.vue'
import SinglePage from './views/SinglePage.vue'
// import NoPage from './views/NoPage.vue'

Vue.use(Router)

export default new Router({
  scrollBehavior () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300);
    })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'front-page',
      component: FrontPage
    },
    {
      path: '/services/:id?/:sub?',
      name: 'services',
      component: ServicesPage,
      alias: '/services/:id'
    },
    /*
    {
      path: '/services/:id',
      name: 'servicesingle',
      component: ServicesSingle
    },
    */
    {
      path: '/about',
      name: 'about',
      component: AboutPage
    },
    {
      path: '/clients',
      name: 'clients',
      component: ClientsPage
    },
    {
      path: '/clients/:id',
      name: 'clientssingle',
      component: ClientsSingle
    },
    {
      path: '/inspirations',
      name: 'inspirations',
      component: InspirationsPage
    },
    {
      path: '/inspirations/:id',
      name: 'inspiration',
      component: InspirationsSingle
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactPage
    },
    {
      path: '/fundusze-europejskie',
      name: 'fundusze',
      component: SinglePage,
      props: { slug: '/fundusze-europejskie/' }
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: SinglePage,
      props: { slug: '/privacy-policy/' }
    },
    {
      path: '/slownik-lean',
      name: 'slownik',
      component: SinglePage,
      props: { slug: '/slownik-lean/' }
    }
  ]
})

