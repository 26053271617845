import { fetchContent } from '../services/staticPages'

export const types = {
  SELECT_COMMIT: 'selectCommit',
  FETCH_COMMIT: 'fetchCommit',
  FETCH_LIST_COMMIT: 'fetchListCommit',
  FETCH_SINGLE_COMMIT: 'fetchSingleCommit'
}

export const actionNames = {
  FETCH: 'fetch',
  FETCH_SINGLE: 'fetchSingle',
  FETCH_LIST: 'fetchList',
  LOAD_PAGE_CONTENT: 'loadPageContent'
}

export default {
  namespaced: true,
  state: {
    contentPage: null,
    cachedPages: []
  },

  mutations: {
    [types.FETCH_COMMIT](state, pageData) {
      state.contentPage = pageData
      const hasPage = state.cachedPages.find(x => x.id === pageData.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, pageData ]
    },
  },

  actions: {
    async [actionNames.FETCH]({ commit }, slug) {
      const contentData = await fetchContent(slug)
      commit(types.FETCH_COMMIT, contentData)
    },

    async [actionNames.LOAD_PAGE_CONTENT]({ state, commit }, slug) {
      const pageContent = state.cachedPages.find(x => x.slug === slug)
      const contentData = (!pageContent) ? await fetchContent(slug) : pageContent

      commit(types.FETCH_COMMIT, contentData)
    }
  }
}
