<template>
  <div class="pagination-cont">
    <ArrowButton :disabled="isPreviousButtonDisabled" @click.native="previousPage">
      ←
    </ArrowButton>
    <PaginationButton
      v-for="paginationTrigger in paginationTriggers"
      :class="{
        'pagination-cont__description--current':
          paginationTrigger === currentPage
      }"
      :key="paginationTrigger"
      :pageNumber="paginationTrigger"
      class="pagination-cont__description"
      @loadPage="onLoadPage"
    />
    <ArrowButton :disabled="isNextButtonDisabled" @click.native="nextPage">
      →
    </ArrowButton>
  </div>
</template>

<script>
import ArrowButton from "./Pagination__Arrow.vue";
import PaginationButton from "./Pagination__Button.vue";

export default {
  components: {
    ArrowButton,
    PaginationButton
  },

  props: {
    currentPage: {
      type: Number,
      required: true
    },

    pageCount: {
      type: Number,
      required: true
    },

    visiblePagesCount: {
      type: Number,
      default: 2
    }
  },

  computed: {
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },

    isNextButtonDisabled() {
      return this.currentPage === this.pageCount;
    },

    paginationTriggers() {
      const currentPage = this.currentPage;
      const pageCount = this.pageCount;
      const visiblePagesCount = this.visiblePagesCount;
      const visiblePagesThreshold = (visiblePagesCount - 1) / 2;
      const pagintationTriggersArray = Array(this.visiblePagesCount - 1).fill(
        0
      );

      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1;
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pagintationTriggersArray[0] + index;
          }
        );

        pagintationTriggers.push(pageCount);

        return pagintationTriggers;
      }

      if (currentPage >= pageCount - visiblePagesThreshold + 1) {
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pageCount - index;
          }
        );

        pagintationTriggers.reverse().unshift(1);

        return pagintationTriggers;
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;
      const pagintationTriggers = pagintationTriggersArray.map(
        (paginationTrigger, index) => {
          return pagintationTriggersArray[0] + index;
        }
      );

      pagintationTriggers.unshift(1);
      pagintationTriggers[pagintationTriggers.length - 1] = pageCount;

      return pagintationTriggers;
    }
  },

  methods: {
    nextPage() {
      this.$emit("nextPage");
    },

    onLoadPage(value) {
      this.$emit("loadPage", value);
    },

    previousPage() {
      this.$emit("previousPage");
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.pagination-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    padding: 0 0 2rem;
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.2rem;
    font-size: 0.76rem;
    font-family: $font-medium;
    font-weight: 400;
    cursor: pointer;
    height: 1.65rem;
    width: 1.65rem;
    line-height: 0.7;
    color: $light-head;
    transition: $transition;

    &--current,
    &:hover {
      background-color: $light-head;
      color: $white
    }
  }

  &__button {
    border: 0;
    height: 1.65rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.7;
    margin: 0 1rem;
    color: $light-head;
    background-color: transparent;
    transition: $transition;

    &:is([disabled]) {
      opacity: 0.5;
    }

    &:not([disabled]) {
      cursor: pointer;

      &:hover {
        background-color: $light-head;
        color: $white;
      }
    }
  }

}
</style>
