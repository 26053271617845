<template>
  <div class="page__description" v-if="content">
    <h1 class="page__description-title">{{ content.title }}</h1>

    <div class="page__description-content">
      <div class="page__description-content-txt" v-if="content.description_short">
        <div v-html="content.description_short"></div>
      </div>
      <div class="page__description-content-txt" v-if="content.description_long">
        <div v-html="content.description_long"></div>
      </div>
      <slot></slot>
      <div class="page__description-content-image">
        <img :src="content.image" class="page__description-image-photo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDescription",
  props: {
    content: Object,
    subtitle: String
  }

}
</script>

<style lang="scss">
@import "@/styles/_theme.scss";

.page__description {
  &-title {
    width: 100%;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil;
    margin: 0 0 1.5rem;
    padding: 0rem 0 2rem;
    position: relative;
    text-transform: uppercase;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: $spacer-width;
      height: $spacer-height;
      background: $pencil;
    }
  }

  &-content {
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    display: block;

    &::before {
      content: " ";
      position: absolute;
      bottom: -2.5rem;
      right: -2rem;
      width: 50%;
      height: 80%;
      max-height: 50vh;
      z-index: 1;
      background-color: $pencil-color;
    }

    &-image {
      width: 100%;
      position: relative;
      z-index: 2;
    }

    &-txt {
      position: absolute;
      display: flex;
      bottom: 9%;
      right: 6%;
      width: 80%;
      padding: 2rem;
      color: $white;
      font-family: $font-family;
      font-size: 1rem;
      line-height: 1.5;
      z-index: 3;

      p {
        margin: 0;

        + p {
          margin-top: 0.75rem;
        }
      }

      > div {
        position: relative;
        z-index: 2;
      }

      &:before {
        content: "";
        background: $pencil-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.75;
      }
    }
  }
}

.content-darkest {
  .page__description {
    &-title {
      color: $darkest;
      padding-top: 0;

      &::before {
        background-color: $darkest;
      }
    }

    &-content::before {
      background-color: $darkest;
    }

    &-content-txt {
      &::before {
        background-color: $darkest;
      }

      &::after {
        content: "";
        position: absolute;
        background-color: $darkest;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
      }
    }
  }
}
</style>
