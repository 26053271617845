<template>
  <div class="page-slownik__container">
    <h1 class="page-slownik__head">
      {{ slownik.title }}
    </h1>
    <ul v-if="slownik.list" class="page-slownik__list">
      <li
        v-for="(item, idx) of slownik.list"
        :key="`item-${idx}`"
      >
        <h2>{{ item.slownik_termin }}</h2>
        <p>{{ item.slownik_opis }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SlownikLean',
  props: ['slownik'],
}
</script>

<style lang="scss">
@import "@/styles/_theme.scss";

.page-slownik {
  &__head {
    width: 100%;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil;
    margin: 0 0 1.5rem;
    padding: 0rem 0 2rem;
    position: relative;
    text-transform: uppercase;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: $spacer-width;
      height: $spacer-height;
      background: $pencil;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;

    li {
      flex: auto;
      max-width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 0 3rem;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }

      h2 {
        font-family: $font-medium;
        padding: 1rem 0;
        margin: 0;
      }

      p {
        margin: 0;
        line-height: 1.7;
      }
    }
  }
}
</style>
