export default {
    0: {
        section : '.intro-head',
        onAir: 0,
        time: 100,
        animItems: null
    },
    10: {
        section : '.intro-head-title',
        onAir: 0,
        time: 100,
        animItems: {
            item: '.showOn',
            time: 125
        }
    },
    800: {
        section : '.intro-head-claims',
        onAir: 0,
        time: 100,
        animItems: {
            item: '.head-claim-item',
            time: 1200
        }
    }
}
