<template>
  <section class="section section-services" id="front-services">
    <div class="section__description" v-if="contentServices">
      <h4>{{ contentServices.subtitle }}</h4>
      <h3>{{ contentServices.title }}</h3>
      <div class="section__description-txt" v-html="contentServices.description_short"></div>
      <router-link
        to="/services/"
        class="btn__cta btn__cta-more-dark">
        <span>POZNAJ NASZE USŁUGI</span>
        <iconArrow class="btn__cta-arrow" />
      </router-link>
      <div class="section-services__image">
        <img :src="contentServices.image" class="section-services__image-photo" />
      </div>
    </div>
    <ul class="section-services__list" v-if="servicesList">
      <li
        class="section-services__list-item"
        v-for="item in servicesList"
        :key="item.id"
        :id="item.id"
      >
        <h4 class="section-services__list-item-title">
          <router-link
              :to="item.slug"
              class="btn__services">
            <span>{{ item.title }}</span>
          </router-link>
        </h4>
      </li>
    </ul>
  </section>
</template>

<style lang="scss">
@import '@/styles/_theme.scss';
@import '@/styles/front-services.scss';
</style>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/services'
import iconArrow from '@/assets/icon-arrow.vue'

export default {
  components: {
    iconArrow
  },

  computed: {
    ...mapState({
      contentServices: state => state.services.contentServices
    }),
  },

  data() {
    return {
      servicesList: null
    }
  },

  async mounted() {
    if (!this.$store.state.services.contentServices) {
      await this.$store.dispatch(
        `services/${actionNames.LOAD_PAGE_CONTENT}`,
        '/services/'
      )
    }
    this.servicesList = Object.values(this.$store.state.services.contentServices.children).map(i => {
      const { id, slug, title } = i
      return { id, slug, title }
    })
    this.$store.state.OnAir.btnWhite = false;
  }
}
</script>
