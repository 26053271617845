<template>
  <div class="page-contact">
    <div class="page-contact__description" v-if="content">
      <h4>{{ content.subtitle }}</h4>
      <h1>Kontakt</h1>
      <div class="page-contact__description-txt" v-html="content.description_long"></div>
      <div class="page-contact__person">
        <img v-if="content.person_photo" :src="content.person_photo.sizes.medium_large" />
        <div class="page-contact__person-info">
          <span class="page-contact__person-name">{{ content.person_name }}</span>
          <span class="page-contact__person-job">{{ content.person_job }}</span>
          <div class="page-contact__person-links">
              <a :href="`mailto:${content.person_email}`" class="page-contact__person-link">
                <iconContact />
                <span>{{ content.person_email }}</span>
              </a>
              <a :href="content.person_linkedin" class="page-contact__person-link" target="_blank">
                <iconLinkedin />
                <span>Linkedin</span>
              </a>
          </div>
        </div>
      </div>
    </div>
    <div class="page-contact__faq" v-if="content">
      <h3>Najczęściej zadawane pytania</h3>
      <ul class="page-contact__faq-list">
        <li v-for="item in content.faq"
          v-bind:key="item.id"
          class="page-contact__faq-item">
          <div class="page-contact__faq-question">
            {{ item.contact_faq_question }}
          </div>
          <div class="page-contact__faq-answer">
            {{ item.contact_faq_answer }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/staticPages'
import iconLinkedin from '@/assets/icon-linkedin.vue'
import iconContact from '@/assets/icon-contact.vue'

export default {

  components: {
    iconLinkedin,
    iconContact
  },

  computed: {
    ...mapState({
      content: state => state.staticPages.contentPage
    }),
  },

  async mounted() {
    await this.$store.dispatch(
      `staticPages/${actionNames.LOAD_PAGE_CONTENT}`,
      '/contact/'
    )
    this.$store.state.OnAir.btnWhite = false
  }
};
</script>


<style lang="scss">
@import "@/styles/_theme.scss";

.page-contact {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10rem 0 3rem;
  margin-bottom: 4rem;
  background-color: $bg-color;

  @media screen and (max-width: 768px) {
    padding: 5rem 0 2rem;
    flex-direction: column;
  }

  &__faq {
    flex: 1;
    max-width: 45%;
    background: $white;
    padding: 3rem;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    h3 {
      font-family: $font-bold;
      font-size: 1.5rem;
      font-weight: 400;
      color: $pencil-light;
      margin: 0 0 1rem;
      padding: 0rem 0 2rem;
      position: relative;
      flex: 1 0 100%;

      &::before {
        position: absolute;
        content: " ";
        bottom: 0.5rem;
        left: 0;
        width: 3rem;
        height: $spacer-height;
        background: $pencil-light;
      }
    }

    &-list {
      list-style: decimal-leading-zero;
      margin: 0;
      padding: 1rem 0 0rem 2rem;
    }
    &-item {
      padding: 0.5rem 0 0;
    }
      &-question {
        display: block;
        padding: 0 0 0.8rem;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-medium;
      }
      &-answer {
        font-size: 0.8rem;
        line-height: 1.6;
        padding-bottom: 2rem;
      }
  }

  &__description {
    padding: 0 3rem 0 4rem;
    flex: 1;
    max-width: 55%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 15vh;

    @media screen and (max-width: 768px) {
      position: relative;
      top: auto;
      max-width: 100%;
      padding: 1rem 2rem;
      flex-direction: column;
    }

    h4 {
      font-family: $font-header;
      font-size: 1rem;
      color: $light-head;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }

    h1 {
      font-family: $font-bold;
      font-size: 2.5rem;
      font-weight: 400;
      color: $pencil;
      margin: 0;
      padding: 0.5rem 0 2rem;
      position: relative;
      flex: 1 0 100%;

      &::before {
        position: absolute;
        content: " ";
        bottom: 0.5rem;
        left: 0;
        width: $spacer-width;
        height: $spacer-height;
        background: $pencil-light;
      }
    }

    &-txt {
      margin: 0;
      padding: 1.5rem 3rem 2.5rem 0;
      font-size: 0.9rem;
      line-height: 1.5;
      font-family: $font-family;
      color: $pencil;
      flex: 1;
      max-width: 60%;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 2rem 0;
      }
    }
  }

  &__person {
    position: relative;
    transition: $transitionNice;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: flex-end;
    max-width: 35%;

    @media screen and (max-width: 768px) {
      max-width: 80%;
      margin: auto;
    }

    img {
      position: relative;
      z-index: 3;
      width: 100%;
      margin: 0 0 -2rem 0;
    }

    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #fff;
      background: $darkest;
      padding: 3.5rem 1.5rem 1rem 1.5rem;
      transition: $transitionNice;
      z-index: 1;
      margin: 0 -2rem 0 2rem;

      @media screen and (max-width: 768px) {
        margin: 0 0 1rem;
      }
    }

    &-name {
      font-size: 1.25rem;
      font-family: $font-medium;
      font-weight: 400;
      line-height: 1.1;
    }

    &-job {
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 400;
      padding-top: 0.8rem;
      font-family: $font-header;
      color: $bgColor;
    }

    &-links {
      padding-top: 1rem;

      a:last-child {
          border: none;
      }
    }

    &-link {
      font-size: 0.85rem;
      font-weight: 400;
      font-family: $font-header;
      color: $white;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid rgba(255,255,255,0.2);
      transition: $transitionSlow;

      svg {
        fill: #fff;
        min-height: 0.9rem;
        min-width: 1rem;
        max-height: 0.9rem;
        max-width: 1rem;
        position: relative;
        margin-right: 0.5rem;
        transition: $transitionSlow;
      }

      &:hover {
        color: $pencil;

        svg {
          fill: $pencil;
        }
      }
    }
  }
}
</style>
