<template>
  <div class="page-single__container">
    <h1 class="page-single__head">
      {{ fundusze.title }}
    </h1>
    <div class="page-single__content" v-html="fundusze.content" />
    <div class="page-single__raport">
      <a :href="fundusze.raport.url" target="_blank" class="page-single__raport-pdf">
        <IconPDF />
        <span>Pobierz raport</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconPDF from '@/assets/icon-pdf'

export default {
  name: 'Fundusze',

  props: ['fundusze'],

  components: {
    IconPDF
  },
}
</script>
