import Vue from 'vue'
import Vuex from 'vuex'
import homepage from './homepage'
import services from './services'
import clients from './clients'
import inspirations from './inspirations'
import staticPages from './staticPages'
import attributes from './attributes'

import SectionFront from '@/store/modules/SectionFront.js'
import ServicesContent from '@/store/modules/SectionIntro.js'
import ServicesDetails from '@/store/modules/ServicesDetails.js'
import ClientsDetails from '@/store/modules/ClientsDetails.js'
import InspirationsDetails from '@/store/modules/Inspirations.js'
import MenuItems from '@/store/modules/MenuItems.js'
import AnimFront from '@/store/animations/AnimFront.js'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homepage,
    services,
    clients,
    inspirations,
    staticPages,
    attributes,
  },
  state: {
    MenuItems: MenuItems,
    AnimFront: AnimFront,
    SectionFront: SectionFront,
    ServicesContent: ServicesContent,
    ServicesDetails: ServicesDetails,
    ClientsDetails: ClientsDetails,
    InspirationsDetails: InspirationsDetails,
    // SectionServices: SectionServices,
    OnAir: {
      section : 'dpc-intro',
      btnWhite : false
    }
  },
  getters: {
    getMainMenu: state => {
      return state.MenuItems.filter(item => item.inMainMenu);
    },
    getMenu: state => {
      return state.MenuItems;
    },
    getAnim: state => {
      return state.AnimMenu;
    },
    getOnAirStatus: state => {
      return state.OnAir;
    },
    getServicesList: state => {
      return state.ServicesContent['services-list'].list;
    },
    getServicesDetails: state => {
      return state.ServicesDetails;
    },
    getClientsSection: state => {
      return state.ClientsDetails.content;
    },
    getClientsDetails: state => {
      return state.ClientsDetails.list;
    },
    getInspirationsSection: state => {
      return state.InspirationsDetails.content;
    },
    getInspirations: state => {
      return state.InspirationsDetails.list;
    }
  }
})

