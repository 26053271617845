<template>
  <section class="page-inspirations is-single">
    <div class="page-loading" v-if="!content" />
    <div class="page-inspirations__container" v-if="content">
      <div class="page-inspirations__head">
        <h1 class="page-inspirations__title">{{ content.title }}</h1>
        <div class="page-inspirations__intro">
          {{ content.intro }}
        </div>
      </div>
      <div class="page-inspirations__image">
        <img :src="content.photo.url" :alt="content.title" />
      </div>
      <div class="page-inspirations__content" v-html="content.content" />
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/inspirations'

export default {
  name: "InspirationsSingle",


  components: {
  },

  computed: {
    ...mapState({
      inspirations: state => state.inspirations.inspirations,
      content: function(state) {
        const id = this.$route.params.id
        return state.inspirations.inspirations.find(i => i.url === id)
      }
    }),
  },

  mounted() {
    this.$store.dispatch(
      `inspirations/${actionNames.LOAD_PAGE_CONTENT}`, '/inspirations/'
    )
  },

  methods: {
  }

}
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.page-inspirations {
  padding: 20vh 0;
  display: flex;

  &.is-single {
    padding-bottom: 2rem;

    h1 {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 5rem 0;
  }

  &__head {
    display: flex;
    max-width: 50%;
    padding: 0 2rem 0 4rem;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 4rem 1rem 1rem;
      order: 1;
    }
  }

  &__image {
    flex: 1 0;
    width: 50%;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      order: 0;
      min-width: 100%;
    }

    img {
      display: block;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      right: -2rem;
      bottom: -2rem;
      background-color: $pencil-color;
    }
  }

  &__content {
    padding: 3rem 4rem 1rem;
    line-height: 1.5;

    @media screen and (max-width: 768px) {
      order: 2;
      padding: 0 1rem 2rem;
    }
  }

  &__intro {
    flex: 1;
    padding: 1rem 2rem 1rem 0rem;
    font-size: 1.1rem;
    line-height: 1.6;
    font-family: $font-medium;
  }

  &__title {
    flex: 0 1;
    width: 100%;
    padding: 0 0 2rem;
    margin: 0 0 2rem 4rem;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil;
    position: relative;
    text-transform: uppercase;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: 7rem;
      height: 3px;
      background: $pencil;
    }
  }
}
</style>
