<template>
  <nav
  class="nav"
  v-bind:class="{ 'on-air': menuOnAir }">
    <div class="nav-fixed">
      <router-link
        to="/"
        class="logo"
        id="logo-dpc"
        :class="{ 'on-air': menuOnAir }"
        @click.native="logoClick">
        <LogoDPC class="logo__img" />
        <LogoDPC class="logo__img-mask" />
      </router-link>
      <a
        href="#"
        id="menu-btn"
        class="btn__menu-burger"
        v-bind:class="{ 'on-air': menuOnAir, 'btn-white' : menuBtnWhite }"
        v-on:click="menuToggle"
      >
        <span></span>
      </a>
    </div>
    <div id="overlay-menu"
      v-if="menuOnAir"
      :class="{
        'on-air': menuOnAir,
        'is-on': menuIsOn,
        'is-off': menuIsOff
      }">
      <ul class="page-menu">
        <li
          class="page-menu-item social-menu"
          v-bind:class="{ 'on-air': menuOnAir }" >
          <a href="mailto:biuro@dpcpolska.pl" target="_blank"><iconContact /></a>
          <a href="https://www.facebook.com/DPCPolska/" target="_blank"><iconFacebook /></a>
          <a href="https://www.linkedin.com/company/dpc-polska/" target="_blank"><iconLinkedin /></a>
        </li>
        <li
          v-for="val in menuItems"
          v-bind:key="val.id"
          class="page-menu-item"
          v-bind:data-id="val.id"
          v-bind:class="{
            'hide-link' : !val.inMainMenu,
          }"
        >
          <router-link
            v-bind:to="val.link"
            v-bind:data-id="val.id"
            v-on:click.native="menuBtnClick"
            v-bind:class="{
              'page-menu-link' : val.inMainMenu,
              'on-air' : val.onAir,
              'lets-hide' : val.letsHide
            }"
          >{{ val.title }}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style lang="scss">
@import "@/styles/_theme.scss";
@import "@/styles/navigation.scss";
</style>

<script>
import LogoDPC from '@/assets/logo-dpc.vue'
import iconLinkedin from '@/assets/icon-linkedin.vue'
import iconFacebook from '@/assets/icon-facebook.vue'
import iconContact from '@/assets/icon-contact.vue'

export default {
  data() {
    return {
      menuOnAir: false,
      menuIsOn: false,
      menuIsOff: false,
      menuItemsOn: false,
      menuAnimating: false,
      menuItems: this.$store.getters.getMainMenu
    };
  },
  components: {
    LogoDPC,
    iconLinkedin,
    iconFacebook,
    iconContact
  },
  props: {
    menuBtnWhite: Boolean
  },
  methods: {
    logoClick: function() {
      if (this.menuOnAir) {
        this.menuHide()
      }
    },
    menuBtnClick: function(e) {
      if (e) e.preventDefault()
      this.menuToggle()
    },
    menuToggle: function(e) {
      if (e) e.preventDefault()
      if (this.menuAnimating === false) {
        if (!this.menuOnAir) {
          this.menuShow()
        } else {
          this.menuHide()
        }
      }
    },
    menuShow: function() {
      this.menuOnAir = true;
      this.menuAnimating = true;
      this.menuIsOn = true;

      let timeSum = 0;
      this.menuItems.forEach(el => {
        setTimeout(() => {
          el.onAir = 1
        }, el.time)
        timeSum += el.time
      });
      setTimeout(() => {
        this.menuAnimating = false
      }, timeSum * 1.1)
    },
    menuHide: function() {
      this.menuIsOn = false;
      this.menuIsOff = true;
      this.menuAnimating = true;

      let timeSum = 0;
      this.menuItems.forEach(el => {
        setTimeout(() => {
          el.letsHide = 1;
          el.onAir = 0;
        }, el.time);
        timeSum += el.time;
      });

      setTimeout(() => {
        this.menuOnAir = false;
      }, timeSum);

      setTimeout(() => {
        this.menuIsOff = false;
        this.menuItems.map(e => {
          e.letsHide = 0;
          return e;
        });
      }, timeSum * 1.6);

      setTimeout(() => {
        this.menuAnimating = false;
      }, 2500);
    }
  }
};
</script>
