import { fetchContent, fetchInspirations, fetchInspiration } from '../services/inspirations'

export const types = {
  SELECT_COMMIT: 'selectCommit',
  FETCH_COMMIT: 'fetchCommit',
  FETCH_LIST_COMMIT: 'fetchListCommit',
  FETCH_SINGLE_COMMIT: 'fetchSingleCommit'
}

export const actionNames = {
  FETCH: 'fetch',
  FETCH_SINGLE: 'fetchSingle',
  FETCH_LIST: 'fetchList',
  LOAD_PAGE_CONTENT: 'loadPageContent'
}

export default {
  namespaced: true,
  state: {
    contentPage: null,
    contentSingle: null,
    inspirations: [],
    cachedPages: [],
    page: 1,
    totalCount: null
  },

  mutations: {
    [types.FETCH_COMMIT](state, pageData) {
      const { children, ...content } = pageData
      state.contentPage = content
      state.inspirations = Object.values(children)
      const hasPage = state.cachedPages.find(x => x.id === pageData.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, pageData ]
    },

    [types.FETCH_SINGLE_COMMIT](state, pageData) {
      state.contentSingle = pageData
      const hasPage = state.cachedPages.find(x => x.id === pageData.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, pageData ]
    },

    [types.FETCH_LIST_COMMIT](state, data) {
      state.inspirations = data
      const hasPage = state.cachedPages.find(x => x.id === data.id)
      if (!hasPage) state.cachedPages = [ ...state.cachedPages, data ]
    }
  },

  actions: {
    async [actionNames.FETCH]({ commit }, slug) {
      const contentData = await fetchContent(slug)
      commit(types.FETCH_COMMIT, contentData)
    },

    async [actionNames.FETCH_SINGLE]({ commit }, slug) {
      const contentData = await fetchInspiration(slug)
      commit(types.FETCH_SINGLE_COMMIT, contentData)
    },

    async [actionNames.FETCH_LIST]({ commit }, slug) {
      const contentData = await fetchInspirations(slug)
      commit(types.FETCH_SINGLE_COMMIT, contentData)
    },

    async [actionNames.LOAD_PAGE_CONTENT]({ state, commit }, slug) {
      const pageContent = state.cachedPages.find(x => x.slug === slug)
      const contentData = (!pageContent) ? await fetchContent(slug) : pageContent

      if (slug === '/inspirations/') {
        commit(types.FETCH_COMMIT, contentData)
      } else {
        commit(types.FETCH_SINGLE_COMMIT, contentData)
      }
    }
  }
}
