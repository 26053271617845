import http from '../helpers/http'

const mapFromRest = function(data) {
  return {
    id: data.id,
    title: data.title,
    slug: data.slug,
    content: data.content
  }
}

export async function fetchPage(pageSlug = null) {
  if (!pageSlug) return false

  const contentData = await http.get(`${pageSlug}`)
  return mapFromRest(contentData)
}

export async function fetchSlownik(pageSlug = null) {
  if (!pageSlug) return false
  const contentData = await http.get(`${pageSlug}`)
  return contentData
}
