<template>
  <section class="page-inspirations">
    <div class="page-inspirations__container">

      <h1 class="page-inspirations__title">Inspiracje</h1>

      <ul class="section-inspirations__list">
        <li class="section-inspirations__list-item"
          v-for="item in articles"
          :key="`article-${item.ID}`">
          <div class="section-inspirations__list-item-photo">
            <router-link
              v-bind:to="item.url"
              class="section-inspirations__list-item-img">
              <img :src="item.photo.thumb" :alt="item.title" />
            </router-link>
          </div>
          <InspirationItem :item="item" />
        </li>
      </ul>

      <Pagination
        v-if="pageCount > 1"
        :current-page="currentPage"
        :page-count="pageCount"
        class="articles-list__pagination"
        @nextPage="pageChangeHandle('next');"
        @previousPage="pageChangeHandle('previous');"
        @loadPage="pageChangeHandle"
      />
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/inspirations'
import InspirationItem from '@/components/InspirationItem.vue'
import Pagination from '@/components/Pagination.vue'
// import PageDescription from '@/components/PageDescription'

export default {
  name: "InspirationsPage",

  components: {
    InspirationItem,
    Pagination,
    // PageDescription
  },

  computed: {
    ...mapState({
      contentPage: state => state.inspirations.contentPage,
      cachedPages: state => state.inspirations.cachedPages,
      inspirations: state => state.inspirations.inspirations
    }),
  },

  data() {
    return {
      articles: [],
      currentPage: 1,
      pageCount: 0,
      visibleItemsPerPageCount: 6,
    }
  },

  mounted() {
    this.$store.state.OnAir.btnWhite = false;
    this.initInspirations();
  },

  methods: {

    async initInspirations() {
      if (!this.inspirations.length) {
        await this.$store.dispatch(
          `inspirations/${actionNames.LOAD_PAGE_CONTENT}`,
          this.$route.path
        )
      }

      if (this.inspirations) {
        let itemsPerPage = this.visibleItemsPerPageCount;
        let offset = (this.currentPage - 1) * itemsPerPage;
        let list = this.inspirations;
        this.articles = list.slice(offset, itemsPerPage * this.currentPage);
        this.pageCount = Math.ceil(
          list.length / this.visibleItemsPerPageCount
        );
      }

    },

    getImgUrl(pet) {
      var images = require.context('../assets/thumbs/', false, /\.png$/)
      return images('./' + pet + ".png")
    },
    pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
          break;
      }

      let itemsPerPage = this.visibleItemsPerPageCount;
      let offset = (this.currentPage - 1) * itemsPerPage;
      let list = this.inspirations;
      let temp = list.slice(offset, itemsPerPage * this.currentPage);
      this.articles = temp;

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },20);

    }
  }

}
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.page-inspirations {
  padding: 20vh 0;
  display: flex;

  @media screen and (max-width: 768px) {
    padding: 5rem 0;
  }

  &__title {
    padding: 0 0 2rem;
    width: 100%;
    font-family: $font-bold;
    font-size: 2rem;
    font-weight: normal;
    color: $pencil;
    margin: 0 4rem 2rem;
    position: relative;
    text-transform: uppercase;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: 7rem;
      height: 3px;
      background: $pencil;
    }
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .section-inspirations {
      &__list {
        width: 100%;
        background: transparent;
        padding: 0 4rem;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
          padding: 0 1rem;
        }

        &-item {
          max-width: 46%;
          border: 0;
          margin: 0 0 5rem;
          padding: 0;

          @media screen and (max-width: 768px) {
            max-width: 47%;
            margin-bottom: 2rem;
          }

          &-details {
            padding: 0 0 0 2rem;

            @media screen and (max-width: 768px) {
              padding: 1rem 0;
            }
          }
          &-title {
            padding: 0;
            font-size: 1.2rem;
          }
          &-intro {
            padding: 1rem 0 2rem;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }

          &-cta {
            @media screen and (max-width: 768px) {
              margin-top: 1rem;
              padding-right: 0.5rem;
              max-width: 100%;
              min-width: 100%;

              svg {
                margin-left: 0.25rem
              }
            }
          }
        }
      }
    }
  }

  .page-content {
    &__description {
      max-width: 40%;
      background-color: #f2f3f4;
      padding: 4rem;
    }

    &__text {
      padding-bottom: 0rem;
    }
  }
}
</style>
