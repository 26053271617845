<template>
  <div class="page-clients"
    :class="{ 'page--loading' : !contentClients }">
    <div class="page-clients__sectors">
      <div class="page-clients__sectors-cont"
        v-if="contentClients">
        <router-link
            :to="item.slug"
            class="page-clients__sectors-item"
            v-for="item in contentClients.children"
            :key="item.id"
            :id="`client-${item.id}`">
          <span class="page-clients__sectors-title">{{ item.title }}</span>
        </router-link>
      </div>
    </div>
    <PageDescription :content="contentClients" class="content-darkest" />

    <div v-if="contentClients" class="page-clients__companies">
      <div class="page-clients__companies-head">
        <h2>{{ contentClients.clients_logos_title }}</h2>
      </div>
      <div class="page-clients__companies-list">
        <div class="page-clients__companies-company"
            v-for="item in contentClients.clients_logos_list"
            :key="item.id"
            :id="`company-${item.id}`">
          <img :src="item.sizes.medium" alt="-" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/clients'
import PageDescription from '@/components/PageDescription'

export default {
  name: "ClientsPage",

  components: {
    PageDescription
  },

  computed: mapState({
    contentClients: state => state.clients.contentClients
  }),

  mounted() {
    this.$store.dispatch(
      `clients/${actionNames.LOAD_PAGE_CONTENT}`,
      this.$route.path
    )
    this.$store.state.OnAir.btnWhite = false
  }

}
</script>

<style lang="scss">
@import "@/styles/_theme.scss";
@import "@/styles/ClientsPage.scss";

.page-clients {
  @media screen and (max-width: 768px) {
    margin-bottom: 5rem;
  }

  .page-content__description {
    max-width: 55%;
  }

  &__companies {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 7rem 5rem 0;

    @media screen and (max-width: 768px) {
      padding: 2rem;
    }

    &-head {
      text-transform: uppercase;
      font-family: $font-bold;

      h2 {
        color: $darkest;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }

    &-company {
      padding: 3rem 3rem 1rem;
      display: block;
      max-width: 20%;

      @media screen and (max-width: 768px) {
        padding: 2rem 1rem 1rem;
        max-width: 50%;

      }

      img {
        display: block;
        max-width: 100%;
        max-height: 80px;
        transition: $transition;
        opacity: 0.5;

        @media screen and (max-width: 768px) {
          max-width: 7rem;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
}
</style>
