<template>
  <div id="app">
    <Navigation v-bind:menuBtnWhite="onair.btnWhite" />
    <transition
        name="fade"
        mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<style lang="scss">
@import '@/fonts/style.scss';
@import '@/styles/_theme.scss';
@import '@/styles/_main.scss';
</style>

<script>
import { actionNames } from '@/store/attributes'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'DPC',
  data() {
    return {
      onair: this.$store.state.OnAir,
    }
  },

  methods: {
    handleScrollApp: function (event) {
      const scrollPos = event.target.scrollingElement.scrollTop;
      const appEl = document.getElementById('app');
      (scrollPos > 0) ? appEl.classList.add('is-scrolled') : appEl.classList.remove('is-scrolled');
    }
  },

  components: {
    Navigation,
    Footer
  },

  created() {
    window.addEventListener("scroll", this.handleScrollApp);
    this.$store.dispatch(`attributes/${actionNames.FETCH_SITE_SETTINGS}`)
    this.$store.dispatch(`attributes/${actionNames.FETCH_FUNDUSZE}`)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollApp);
  }
};
</script>
