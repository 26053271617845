<template>
  <section class="section section-clients">

    <div class="section-clients__sectors" v-if="content">
      <router-link
        class="section-clients__sectors-item"
        v-for="item in content.children"
        :to="item.slug"
        :key="item.id"
        :id="item.id">
        <div class="intro-clients__sector-head" :class="item.id">
          {{ item.title }}
        </div>
      </router-link>
      <router-link
        to="/clients/"
        class="section-clients__sectors-item is-active">
        <span>wszystkie<br/>case studies</span>
      </router-link>
    </div>

    <div class="section-clients__cont">
      <div class="section__description" v-if="content">
        <h4>{{ content.subtitle }}</h4>
        <h3>{{ content.title }}</h3>
        <div class="section__description-txt" v-html="content.description_short"></div>
      </div>
      <div class="section-clients__image">
        <img src="../../assets/clients-image.jpg" class="section-clients__image-photo" />
      </div>
    </div>

  </section>
</template>

<style lang="scss">
@import '@/styles/_theme.scss';
@import '@/styles/front-clients.scss';
</style>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/clients'

export default {
  name: "SectionClients",

  computed: mapState({
    content: state => state.clients.contentClients
  }),

  mounted() {
    this.$store.dispatch(
      `clients/${actionNames.LOAD_PAGE_CONTENT}`,
      '/clients/'
    )
    this.$store.state.OnAir.btnWhite = false
  }
}
</script>
