<template>
  <section class="intro-page">
    <div class="page-loading" v-if="!content" />
    <div class="intro-page__cont" v-if="content">
      <h1 class="intro-page__cont-title">
        <span v-html="content.h1" />
      </h1>
      <img v-if="content.bg" :src="content.bg.url" class="intro-page__cont-image" alt="" />
    </div>

    <div class="intro-page__welcome" v-if="content">
      <div class="intro-page__scroll" @click="scrollDown">
        <iconScroll />
      </div>
      <p class="intro-page__welcome-txt">
        {{ content.intro }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/inspirations'
import iconScroll from '@/assets/icon-scroll.vue'

export default {
  name: 'SectionIntro',

  computed: {
    ...mapState({
      content: state => state.homepage.content,
    })
  },

  components: {
    iconScroll
  },

  methods: {
    scrollDown() {
      const el = document.getElementById("front-services")
      window.console.log(['scroll', el])
      el.scrollIntoView({ behavior: "smooth" })
    },
  },

  mounted: function () {
    this.$store.state.OnAir.btnWhite = false
    this.$store.dispatch(
      `homepage/${actionNames.FETCH}`, '/homepage'
    );
  }
}
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.intro-page {
  flex: 1 100%;
  flex-direction: column;
  background: $white;

  @media screen and (max-width: 768px) {
    margin-top: 3rem;
  }

  &__cont {
    display: block;
    position: relative;
    overflow: hidden;
    height: 33rem;

    @media screen and (max-width: 768px) {
      height: 20rem;
    }

    &-title {
      position: absolute;
      left: 46%;
      top: 29%;
      width: 43%;
      padding: 3rem;
      margin: 0;
      font-weight: normal;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        top: 40%;
        left: 1rem;
        width: calc(100% - 2rem);
        margin: 0;
        padding: 2rem;
      }

      span {
        font-family: $font-medium;
        font-weight: normal;
        font-size: 2.1rem;
        line-height: 1.2;
        color: $white;
        position: relative;
        z-index: 2;
        letter-spacing: -1px;

        @media screen and (max-width: 768px) {
          font-size: 1.35rem;
          line-height: 1.3;
        }

        &::selection {
          background-color: $white;
          color: $pencil;
        }
      }

      &:before {
        content: "";
        background: $pencil-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        mix-blend-mode: hard-light;
        opacity: 0.85;
      }
    }

    &-image {
      @media screen and (max-width: 768px) {
        max-width: unset;
        height: 50vh;
        position: relative;
        left: 0;
        top: 0;
        transform: translateX(-50%);
      }
    }
  }

  &__welcome {
    display: flex;
    height: 27vh;
    align-items: center;
    justify-content: center;
    background: $white;
    transition: $transition;
    transition-delay: 0.3s;
    position: relative;

    &-txt {
      font-family: $font-header;
      font-size: 1.55rem;
      margin: 0;
      max-width: 85%;
      line-height: 1.5;

      @media screen and (max-width: 768px) {
        font-size: 1.1rem;
        max-width: 100%;
        padding: 0 1.5rem 0 2.5rem;
      }
    }
  }

  &__scroll {
    fill: $pencil;
    stroke: $white;
    stroke-width: 3px;
    position: absolute;
    display: block;
    left: 1rem;
    top: 5rem;
    animation: scrollSvgPulse 8s ease-in-out 0s infinite;
    transform: translate3d(0,0,0);
    transition: $transition;
    opacity: 0.3;
    cursor: pointer;
    z-index: 9;

    svg {
      position: relative;
      fill: $pencil;
      transform: rotate(90deg);
      max-width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 14px;
      height: 14px;
      top: 2.65rem;
      left: 0;
    }
  }
}

@keyframes scrollSvgPulse {
  0%   { transform: translate3d(0,0,0); }
  25%  { transform: translate3d(0,0,0); }
  30%  { transform: translate3d(0,10px,0); }
  35%  { transform: translate3d(0,0,0); }
  50%  { transform: translate3d(0,0,0); }
  55%  { transform: translate3d(0,10px,0); }
  60%  { transform: translate3d(0,0,0); }
  100% { transform: translate3d(0,0,0); }
}

.is-scrolled {
  .intro-page__scroll {
    opacity: 0;
  }
  .intro-page__welcome {
    height: 40vh;
  }
}

</style>
