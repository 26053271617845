export default {
  'content' : {
    subtitle: 'Ciągłe doskonalenie',
    title: 'Inspiracje',
    description: 'Nasze podejście opieramy na wieloletnim doświadczeniu, nie jest ono szablonowe i zazwyczaj indywIDualne dla każdego klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.',
  },
  'list': [
      {
        ID: '0',
        url: '/inspirations/czym-jest-strategia-lean-efekty-biznesowe',
        title: 'Czym jest Strategia Lean i jakie są jej efekty biznesowe?',
        intro: 'W jaki sposób zbudować efektywną, sprawną organizację biznesową, która dodatkowo będzie stale się doskonalić? Odpowiedzią na to częste wyzwanie biznesowe jest Strategia Lean. Strategiczne podejście do Lean zakłada – jako rezultat pewnej transformacji – wypracowanie i wdrożenie nowych standardów.',
        image: 'inspiration-3',
        category: 'strategia lean',
        date: '2020-05-10'
      },
      {
        ID: '1',
        url: '/inspirations/michael-balle-kultura-uczenia-sposobem',
        title: 'Dr Michael Ballé: Kultura uczenia się sposobem na budowanie przewagi konkurencyjnej',
        intro: 'Wszyscy wiemy, że Lean ma swoje początki w japońskich fabrykach Toyoty. Nie wszyscy jednak wiedzą, że istotą Lean jest umiejętność uczenia się poprzez rozwiązywanie problemów.',
        image: 'inspiration-1',
        category: 'wywiady',
        date: '2020-05-03'
      },
      {
        ID: '2',
        url: '/inspirations/lean-to-nie-tylko-procesy',
        title: 'Lean to nie tylko procesy... o jakich innych aspektach często zapominamy?',
        intro: 'Proces doskonalenia procesów w firmie oparty na Lean Management ma charakter ciągły oraz przekrojowy. Ciągły dlatego, że Lean nie można wdrożyć, to zmiana kulturowa organizacji, gdzie nieprzerwalnie kwestionujemy i zmieniamy rzeczywistość. Przekrojowy dlatego, że dotyka wielu obszarów oraz aspektów życia firmy.',
        image: 'inspiration-1',
        category: 'raporty',
        date: '2020-05-01'
      },
      {
        ID: '3',
        url: '/inspirations/lean-szansa-dla-zbrojeniowki',
        title: 'Lean Management: szansa dla zbrojeniówki w Polsce',
        intro: 'Wszyscy wiemy, że Lean ma swoje początki w japońskich fabrykach Toyoty. Nie wszyscy jednak wiedzą, że istotą Lean jest umiejętność uczenia się poprzez rozwiązywanie problemów.',
        image: 'inspiration-4',
        category: 'wywiady',
        date: '2020-04-29'
      },
      {
        ID: '4',
        url: '/inspirations/lean-to-nie-tylko-procesy',
        title: 'Lean to nie tylko procesy... o jakich innych aspektach często zapominamy?',
        intro: 'Proces doskonalenia procesów w firmie oparty na Lean Management ma charakter ciągły oraz przekrojowy. Ciągły dlatego, że Lean nie można wdrożyć, to zmiana kulturowa organizacji, gdzie nieprzerwalnie kwestionujemy i zmieniamy rzeczywistość. Przekrojowy dlatego, że dotyka wielu obszarów oraz aspektów życia firmy.',
        image: 'inspiration-2',
        category: 'raporty',
        date: '2020-04-27'
      },
      {
        ID: '5',
        url: '/inspirations/michael-balle-kultura-uczenia-sposobem',
        title: 'Dr Michael Ballé: Kultura uczenia się sposobem na budowanie przewagi konkurencyjnej',
        intro: 'Wszyscy wiemy, że Lean ma swoje początki w japońskich fabrykach Toyoty. Nie wszyscy jednak wiedzą, że istotą Lean jest umiejętność uczenia się poprzez rozwiązywanie problemów.',
        image: 'inspiration-3',
        category: 'wywiady',
        date: '2020-04-25'
      },
      {
        ID: '6',
        url: '/inspirations/lean-to-nie-tylko-procesy',
        title: 'Lean to nie tylko procesy... o jakich innych aspektach często zapominamy?',
        intro: 'Proces doskonalenia procesów w firmie oparty na Lean Management ma charakter ciągły oraz przekrojowy. Ciągły dlatego, że Lean nie można wdrożyć, to zmiana kulturowa organizacji, gdzie nieprzerwalnie kwestionujemy i zmieniamy rzeczywistość. Przekrojowy dlatego, że dotyka wielu obszarów oraz aspektów życia firmy.',
        image: 'inspiration-1',
        category: 'raporty',
        date: '2020-04-20'
      },
      {
        ID: '7',
        url: '/inspirations/michael-balle-kultura-uczenia-sposobem',
        title: 'Dr Michael Ballé: Kultura uczenia się sposobem na budowanie przewagi konkurencyjnej',
        intro: 'Wszyscy wiemy, że Lean ma swoje początki w japońskich fabrykach Toyoty. Nie wszyscy jednak wiedzą, że istotą Lean jest umiejętność uczenia się poprzez rozwiązywanie problemów.',
        image: 'inspiration-2',
        category: 'wywiady',
        date: '2020-04-17'
      },
      {
        ID: '8',
        url: '/inspirations/lean-to-nie-tylko-procesy',
        title: 'Lean to nie tylko procesy... o jakich innych aspektach często zapominamy?',
        intro: 'Proces doskonalenia procesów w firmie oparty na Lean Management ma charakter ciągły oraz przekrojowy. Ciągły dlatego, że Lean nie można wdrożyć, to zmiana kulturowa organizacji, gdzie nieprzerwalnie kwestionujemy i zmieniamy rzeczywistość. Przekrojowy dlatego, że dotyka wielu obszarów oraz aspektów życia firmy.',
        image: 'inspiration-4',
        category: 'raporty',
        date: '2020-03-11'
      },
  ],
}
