import { fetchContent } from '../services/homepage'

export const types = {
  FETCH_COMMIT: 'fetchCommit',
}

export const actionNames = {
  FETCH: 'fetch',

}

export default {
  namespaced: true,
  state: {
    content: null,
  },

  mutations: {
    [types.FETCH_COMMIT](state, pageData) {
      state.content = pageData
    },
  },

  actions: {
    async [actionNames.FETCH]({ commit }, slug) {
      const contentData = await fetchContent(slug)
      commit(types.FETCH_COMMIT, contentData)
    },
  }

}
