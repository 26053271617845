var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav",class:{ 'on-air': _vm.menuOnAir }},[_c('div',{staticClass:"nav-fixed"},[_c('router-link',{staticClass:"logo",class:{ 'on-air': _vm.menuOnAir },attrs:{"to":"/","id":"logo-dpc"},nativeOn:{"click":function($event){return _vm.logoClick.apply(null, arguments)}}},[_c('LogoDPC',{staticClass:"logo__img"}),_c('LogoDPC',{staticClass:"logo__img-mask"})],1),_c('a',{staticClass:"btn__menu-burger",class:{ 'on-air': _vm.menuOnAir, 'btn-white' : _vm.menuBtnWhite },attrs:{"href":"#","id":"menu-btn"},on:{"click":_vm.menuToggle}},[_c('span')])],1),(_vm.menuOnAir)?_c('div',{class:{
      'on-air': _vm.menuOnAir,
      'is-on': _vm.menuIsOn,
      'is-off': _vm.menuIsOff
    },attrs:{"id":"overlay-menu"}},[_c('ul',{staticClass:"page-menu"},[_c('li',{staticClass:"page-menu-item social-menu",class:{ 'on-air': _vm.menuOnAir }},[_c('a',{attrs:{"href":"mailto:biuro@dpcpolska.pl","target":"_blank"}},[_c('iconContact')],1),_c('a',{attrs:{"href":"https://www.facebook.com/DPCPolska/","target":"_blank"}},[_c('iconFacebook')],1),_c('a',{attrs:{"href":"https://www.linkedin.com/company/dpc-polska/","target":"_blank"}},[_c('iconLinkedin')],1)]),_vm._l((_vm.menuItems),function(val){return _c('li',{key:val.id,staticClass:"page-menu-item",class:{
          'hide-link' : !val.inMainMenu,
        },attrs:{"data-id":val.id}},[_c('router-link',{class:{
            'page-menu-link' : val.inMainMenu,
            'on-air' : val.onAir,
            'lets-hide' : val.letsHide
          },attrs:{"to":val.link,"data-id":val.id},nativeOn:{"click":function($event){return _vm.menuBtnClick.apply(null, arguments)}}},[_vm._v(_vm._s(val.title))])],1)})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }