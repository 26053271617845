export default
    [
    {
        'id': 1,
        'title': 'Home',
        'link': '/',
        'onAir': 0,
        'letsHide': 0,
        'time': 0,
        'inMainMenu': 0
    },
    {
        'id': 2,
        'title': 'Obszary działania',
        'link': '/services/',
        'onAir': 0,
        'letsHide': 0,
        'time': 0,
        'inMainMenu': 1
    },
    {
        'id': 3,
        'title': 'Zaufali nam',
        'link': '/clients/',
        'onAir': 0,
        'letsHide': 0,
        'time': 100,
        'inMainMenu': 1
    },
    {
        'id': 4,
        'title': 'Inspiracje',
        'link': '/inspirations/',
        'onAir': 0,
        'letsHide': 0,
        'time': 200,
        'inMainMenu': 1
    },
    {
        'id': 5,
        'title': 'Kontakt',
        'link': '/contact/',
        'onAir': 0,
        'letsHide': 0,
        'time': 300,
        'inMainMenu': 1
    },
    {
        'id': 6,
        'title': 'O nas',
        'link': '/about/',
        'onAir': 0,
        'letsHide': 0,
        'time': 400,
        'inMainMenu': 1
    },
    {
        'id': 7,
        'title': 'Polityka prywatności',
        'link': '/privacy-policy/',
        'onAir': 0,
        'letsHide': 0,
        'time': 400,
        'inMainMenu': 0
    },
    {
        'id': 8,
        'title': 'Słownik lean',
        'link': '/slownik-lean/',
        'onAir': 0,
        'letsHide': 0,
        'time': 500,
        'inMainMenu': 0
    }
]
