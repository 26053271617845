export default {
  'services-home' : {
    title: 'Obszary działania',
    subtitle: 'Jak możemy pomóc twojemu biznesowi?',
    description: "Każda organizacja musi rozwijać i doskonalić standardy zarządzania. Nasza odpowiedź na to wyzwanie to perspektywa wartości dodanej, zarówno w odniesieniu do ludzi jak i procesów. Dlatego operacjonalizację strategii firmy budujemy w  oparciu o trzy fundamentalne standardy."
  },
  'services-list': {
    list: [
      {
        id: 'programy-rozwojowe',
        slug: '/services/programy-rozwojowe',
        title: 'Programy rozwojowe',
        description: 'Jedną z najważniejszych ról liderów jest rozwijanie swoich pracowników. Pozyskiwanie nowych umiejętności, kompetencji oraz wiedzy to fundament budowania kultury uczenia się.<br/><br/>W organizacjach wysokoefektywnych rozwój pracowników to proces, którego efektem jest motywowanie procowników do wdrażania nowych pomysłów poprzez kwestionowanie obecnego statusu oraz eksperymentowanie.'

      },
      {
        id: 'analizy-i-diagnozy',
        slug: '/services/analizy-i-diagnozy',
        title: 'Analizy i diagnozy',
        description: 'Podejmowanie decyzji to jeden z najważniejszych procesów, jakie zachodzą w organizacjach. Najlepiej podejmować je w oparciu o dane i fakty, a nie w oparciu o opinie i poglądy innych osób. Pozyskiwanie informacji zarządczej jest zatem ważnym elementem działania każdej organizacji, a w szczególności osób zarządzających.'
      },
      {
        id: 'strategia-lean',
        slug: '/services/strategia-lean',
        title: 'Strategia Lean',
        description: 'W DPC tworzymy indywidualne programy rozwojowe z myśla o "uzbrajaniu" pracowników, lierów* w umiejętności, kompetencje i narzędzia, które w pozwolą im zwiększać efektywność swoich organizacji. Celem naszego działania jest zbudowanie systemu zarządzania, dzięki któremu nasi klienci będą skutecznie: realizować swoje cele, budować przewagę konkurencyjną a w konsekwencji rozwijać organizację.'
      },
      {
        id: 'system-zarzadzania-efektywnoscia',
        slug: '/services/system-zarzadzania-efektywnoscia',
        title: 'System zarządzania efektywnością',
        description: 'Nasze podejście opieramy na wieloletnim doświadczeniu, nie jest ono szablonowe i zazwyczaj indywidualne dla każdego klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.'
      },
      {
        id: 'warsztaty-doskonalenia',
        slug: '/services/warsztaty-doskonalenia',
        title: 'Warsztaty doskonalenia',
        description: 'Jeśli organizacja mogłaby wybrać tylko jedną metodę szybkiego odblokowania wartości, jakie podejście powinno znaleźć się na szczycie listy? Jeśli firma mogłaby zastosować tylko jedną metodę kształtowania swojej kultury w celu ciągłego doskonalenia, jakiego narzędzia powinna użyć? Oczywiście Warsztatów Doskonalenia - 5 dniowych intensywnych warsztatów wdrożeniowych, podczas których zespół identyfikuje, określa i wprowadza usprawnienia w procesie.'
      },
      {
        id: 'robotic-process-automation',
        slug: '/services/robotic-process-automation',
        title: 'Robotic Process Automation',
        description: 'Jeśli Twoje procesy są już po standaryzacji i optymalizacji czas na kolejny krok – ich automatyzację! Rozumiejąc obecne wyzwania biznesowe wspieramy w budowaniu przewagi konkurencyjnej także poprzez automatyzację i robotyzację procesów.'
      }
    ]
  },
  'standards-list' : {
    list : [
      {
        id: '/services/przywodztwo',
        title: 'Standard przywództwa',
        desc: 'Strategia lean chroni przed skrajnościami zarówno sztywnego planowania, jak i nieograniczonego eksperymentowania. W tych ramach strategia zapewnia ogólny kierunek i dostosowanie. Służy zarówno jako ekran, który muszą przejść nowe pomysły, jak i miernik oceny sukcesu eksperymentów. Strategia pozwala - zachęca - pracowników pierwszej linii do kreatywności, zapewniając jednocześnie, że pozostaną na tej samej stronie z resztą organizacji i będą wykorzystywać tylko wartościowe możliwości.',
        onAir: false
      },
      {
        id: '/services/procesy',
        title: 'Standard procesów',
        desc: 'Procesowanie lean chroni przed skrajnościami zarówno sztywnego planowania, jak i nieograniczonego eksperymentowania. W tych ramach strategia zapewnia ogólny kierunek i dostosowanie. Służy zarówno jako ekran, który muszą przejść nowe pomysły, jak i miernik oceny sukcesu eksperymentów. Strategia pozwala - zachęca - pracowników pierwszej linii do kreatywności, zapewniając jednocześnie, że pozostaną na tej samej stronie z resztą organizacji i będą wykorzystywać tylko wartościowe możliwości.',
        onAir: false
      },
      {
        id: '/services/doskonalenie',
        title: 'Standard doskonalenia',
        desc: 'Doskonalenie lean chroni przed skrajnościami zarówno sztywnego planowania, jak i nieograniczonego eksperymentowania. W tych ramach strategia zapewnia ogólny kierunek i dostosowanie. Służy zarówno jako ekran, który muszą przejść nowe pomysły, jak i miernik oceny sukcesu eksperymentów. Strategia pozwala - zachęca - pracowników pierwszej linii do kreatywności, zapewniając jednocześnie, że pozostaną na tej samej stronie z resztą organizacji i będą wykorzystywać tylko wartościowe możliwości.',
        onAir: false
      }
    ]
  },
  'clients-home' : {
    subtitle: 'Klienci i kompetencje w sektorach biznesowych',
    title: 'Zaufali nam',
    description: 'Celem naszego działania jest zbudowanie systemu zarządzania, dzięki któremu nasi klienci będą skutecznie: realizować swoje cele, budować przewagę konkurencyjną a w konsekwencji rozwijać organizację.<br/><br/>Nasze podejście opieramy na wieloletnim doświadczeniu, nie jest ono szablonowe i zazwyczaj indywidualne dla każdego klienta. Sprawdziło się wielokrotnie zarówno w branżach produkcyjnych jak i usługowych.',
    list: [
      {
        id: 'sektor-kolejowy',
        link: '/clients/sektor-kolejowy',
        title: 'Sektor kolejowy',
      },
      {
        id: 'branza-hutnicza',
        link: '/clients/branza-hutnicza',
        title: 'Branża hutnicza',
      },
      {
        id: 'farmacja',
        link: '/clients/farmacja',
        title: 'Farmacja',
      },
      {
        id: 'administracja-publiczna',
        link: '/clients/administracja-publiczna',
        title: 'Administracja publiczna',
      },
      {
        id: 'sektor-bankowy',
        link: '/clients/sektor-bankowy',
        title: 'Sektor bankowy',
      },
      {
        id: 'logistyka',
        link: '/clients/logistyka',
        title: 'Logistyka',
      }
    ],
    elements: [
      { id: 1, title: 'Advanced Analytics' },
      { id: 2, title: 'Agile' },
      { id: 3, title: 'Change Management' },
      { id: 4, title: 'Corporate Finance' },
      { id: 5, title: 'Customer Strategy and Marketing' },
      { id: 6, title: 'Operations' },
    ]
  },
}
