import http from '../helpers/http'

export async function fetchSettings() {
  const settingsData = await http.get('/settings/')
  return settingsData
}

export async function fetchFundusze(pageSlug = '/fundusze-europejskie/') {
  const contentData = await http.get(`${pageSlug}`)
  return contentData
}
