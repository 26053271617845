import http from '../helpers/http'

export async function fetchContent(pageSlug = 'inspirations') {
  const contentData = await http.get(`${pageSlug}`)
  return contentData
}

export async function fetchInspirations(page = 1) {
  const inspirations = await http.get(`inspirations/list/${page}`)
  return inspirations
}

export async function fetchInspiration(pageSlug = null) {
  const data = await http.get(`${pageSlug}`)
  return data
}
