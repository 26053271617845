import { apiBaseUrl } from '../config'

const httpConfig = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

function makeRequest(path, method, config = {}) {
  return fetch(`${apiBaseUrl}${path}`, {
    method,
    ...httpConfig,
    ...config,
    headers: {
      ...httpConfig.headers,
      ...(config.headers ? config.headers : {}),
      // 'Authorization': `Bearer ${getToken()}`
    }
  }).then((response) => {
    if (response.status === 200 || response.status === 201 || response.status === 202) {
      return response.json()
    } else {
      return Promise.reject(response.statusText)
    }
  })
}

export function get(path, config = {}) {
  return makeRequest(path, 'GET', config)
}

export default {
  get
}
