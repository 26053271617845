<template>
  <footer class="footer" id="footer">
    <div class="footer__details">
      <h3 class="footer__details-head">Kontakt</h3>
      <div class="footer__details-address">
        <p v-if="company" v-html="company.company_address">
        <div v-if="socials" class="footer__details-address-icons">
          <a :href="socials.social_fb" target="_blank" class="footer__details-address-link"><iconFacebook /> Facebook</a>
          <a :href="socials.social_li" target="_blank" class="footer__details-address-link"><iconLinkedin /> Linkedin</a>
        </div>
      </div>
      <div class="footer__details-people" v-if="company">
        <span v-for="(item, i) of company.company_contacts" :key="i">
          <span>{{ item.contact_title }}</span>
          <a :href="telephone(item.contact_tel)">{{ item.contact_tel }}</a>
        </span>
        <span><span>E-mail</span> <a :href="`mailto:${email}`">{{ email }}</a></span>
      </div>
      <div v-if="company" class="footer__img">
        <img :src="company.company_photo.sizes.large" class="footer__img-file" />
      </div>
    </div>
    <ContactForm />

    <ul class="footer__menu">
      <li v-for="val in menuItems"
          :key="val.id"
          class="footer__menu-item"
          :data-id="val.id">
        <router-link
          v-bind:to="val.link"
          v-bind:data-id="val.id"
          class="footer__menu-link">
          {{ val.title }}
        </router-link>
      </li>
    </ul>

    <router-link
      v-if="fundusze && fundusze.slug && page === 'front-page'"
      :to="fundusze.slug"
      class="footer__fundusze">
      <span
        v-for="logo of fundusze.logos"
        :key="logo.ID"
        class="footer__fundusze-item">
          <img :src="logo.url" :alt="logo.title" class="footer__fundusze-logo" />
      </span>
    </router-link>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import ContactForm from '@/components/ContactForm.vue'
import iconLinkedin from '@/assets/icon-linkedin.vue'
import iconFacebook from '@/assets/icon-facebook.vue'

export default {
  data() {
    return {
      menuItems: this.$store.getters.getMenu
    }
  },
  components: {
    ContactForm,
    iconLinkedin,
    iconFacebook
  },

  computed: {
    ...mapState({
      company: state => state.attributes.company,
      email: state => state.attributes.email,
      socials: state => state.attributes.socials,
      fundusze: state => state.attributes.fundusze,
      page() { return this.$route.name }
    }),
  },

  methods: {
    telephone(tel) {
      const parsed = tel.replace(/ /g,"").replace(/\(/g, "").replace(/\)/g, "").replace(/\+/g, "")
      return `tel:${parsed}`
    }
  },

  mounted() {
    const appStyle = document.getElementById("app")
    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          appStyle.classList.add('at-contact')
        } else {
          appStyle.classList.remove('at-contact')
        }
      })
    }
    this.onair = new IntersectionObserver(callback, {
      threshold: 0.8
    })
    this.onair.observe(this.$el)
  },
}
</script>

<style lang="scss">
@import "@/styles/_theme.scss";
@import "@/styles/_main.scss";
@import "@/styles/footer.scss";

.footer__fundusze {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #fff;
	padding: 0.5rem 3rem;
  transition: $transitionSlow;
  transform: translateY(0%);
  text-decoration: none;

  &-logo {
    max-height: 4rem;
    max-width: 10rem;
    display: block;
  }
}
#app.is-scrolled {
  .footer__fundusze {
    transform: translateY(100%);
  }
}
</style>
