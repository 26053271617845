<template>
  <div class="front-page">
    <SectionIntro />
    <SectionServices />
    <SectionClients />
    <SectionInspirations />
  </div>
</template>

<script>
import SectionIntro from '@/components/front/intro.vue'
import SectionServices from '@/components/front/services.vue'
import SectionClients from '@/components/front/clients.vue'
import SectionInspirations from '@/components/front/inspirations.vue'

export default {
  name: 'FrontPage',
  components: {
    SectionIntro,
    SectionServices,
    SectionClients,
    SectionInspirations
  }
}
</script>

<style lang="scss">
@import '@/styles/_theme.scss';

.front-page {
  position: relative;
  z-index: 2;
  background: $bg-color;
  display: flex;
  flex-direction: column;

  &::after {
    content: " ";
    position: fixed;
    width: 100%;
    height: 1.5rem;
    bottom: 0;
    z-index: 9999;
    background: $white;
    display: block;
    left: 0;
    transition: $transition-fast;
  }
}
</style>
