<template>
  <div class="contact-form">
    <h3 class="contact-form__head">Napisz do nas</h3>
    <form id="form-contact" @submit.prevent="checkForm">
      <div class="contact-form__fieldset">
        <div class="contact-form__field">
          <label>Nazwa firmy</label>
          <input
          type="text"
          name="company"
          v-model="input.company" />
        </div>
        <div class="contact-form__field">
          <label>Twój e-mail</label>
          <input
          type="text"
          name="email"
          v-model="input.email" />
        </div>
        <div class="contact-form__field">
          <label>Treść wiadomości</label>
          <textarea
          type="text"
          name="message"
          v-model="input.message" ></textarea>
        </div>
      </div>
      <button type="submit" class="btn__cta btn__cta-submit btn__cta-more-dark">
        <span>wyślij wiadomość</span>
        <iconArrow class="btn__cta-arrow" />
      </button>
    </form>
  </div>
</template>

<style lang="scss">
@import "@/styles/_theme.scss";
@import "@/styles/_main.scss";
</style>

<script>
import iconArrow from '@/assets/icon-arrow.vue'
export default {
  components: {
    iconArrow
  },

  data() {
    return {
      errors: [],
      input: {
        email: "",
        company: "",
        message: ""
      },
      loginStatus: {}
    }
  }
};
</script>
