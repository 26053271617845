<template>
  <div v-if="contentSingleClient" class="clients__page"
      :class="{ 'page--loading' : !contentSingleClient }">
    <div class="clients__page__header" v-if="contentSingleClient">
      <h4>Case studies</h4>
      <h1>{{ contentSingleClient.title }}</h1>
    </div>
    <div class="clients__page__info" v-if="contentSingleClient.info">
      <div class="clients__page__info-cont" v-if="contentSingleClient.info">
        <strong>Sektor</strong>
        <span>{{ contentSingleClient.info.case_sector }}</span>
      </div>
      <div class="clients__page__info-cont" v-if="contentSingleClient.info">
        <strong>Lokalizacja</strong>
        <span>{{ contentSingleClient.info.case_location }}</span>
      </div>
      <div class="clients__page__info-cont" v-if="contentSingleClient.info">
        <strong>Zatrudnienie</strong>
        <span>{{ contentSingleClient.info.case_employment }}</span>
      </div>
    </div>
    <div class="clients__page__details" v-if="contentSingleClient.effects">
      <div class="clients__page__details-item">
        <h3>Problem</h3>
        <div class="clients__page__details-desc" v-html="contentSingleClient.problem" />
      </div>
      <div class="clients__page__details-item">
        <h3>Działania</h3>
        <div class="clients__page__details-desc" v-html="contentSingleClient.actions" />
      </div>
      <div class="clients__page__details-item">
        <h3>Efekty</h3>
        <div class="clients__page__details-desc" v-html="contentSingleClient.effects" />
      </div>
      <div class="clients__page__kpis">
        <ul class="clients__page__kpis-list">
          <li class="clients__page__kpis-item"
              v-for="(item, i) in contentSingleClient.kpis"
              v-bind:key="`kpi-${i}`">
            <span class="clients__page__kpis-item-value" v-html="item.kpis_number" />
            <span class="clients__page__kpis-item-label" v-html="item.kpis_title" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/clients'

export default {
  name: 'ClientsSingle',

 computed: mapState({
    contentSingleClient: state => state.clients.contentSingleClients
  }),

  mounted() {
    this.$store.dispatch(
      `clients/${actionNames.LOAD_PAGE_CONTENT}`,
      this.$route.path
    )
    this.$store.state.OnAir.btnWhite = false
  }
}
</script>


<style lang="scss">
@import "@/styles/_theme.scss";

.clients__page {
  padding: 20vh 0 3rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: $bg-color;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    padding-top: 8rem;
  }

  h4 {
    font-family: $font-header;
    font-size: 0.778rem;
    color: $darkest;
    opacity: 0.7;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: $font-bold;
    font-size: 2.5rem;
    font-weight: 400;
    color: $darkest;
    margin: 0;
    padding: 0.5rem 0 2rem;
    position: relative;
    line-height: 1.1;

    &::before {
      position: absolute;
      content: " ";
      bottom: 0.5rem;
      left: 0;
      width: $spacer-width;
      height: $spacer-height;
      background: $darkest;
      opacity: 0.5;
    }
  }

  &__header {
    flex: 1;
    padding: 0 0 0 4rem;

    @media screen and (max-width: 768px) {
      padding-left: 2rem;
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin-top: -1.5rem;
    width: 30%;
    position: relative;

    @media screen and (max-width: 768px) {
      margin: 2rem 0 0;
      width: 100%;
      flex: 1;
    }

      &::after {
        content: "";
        background-color: $white;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        left: 0rem;
        top: 0rem;
      }

      &::before {
        content: "";
        background-color: $darkest;
        width: 80%;
        height: 80%;
        position: absolute;
        z-index: 1;
        right: -1rem;
        bottom: -1.5rem;
      }

    &-cont {
      display: flex;
      justify-content: space-between;
      font-size: 0.9rem;
      border-bottom: 1px solid $grey;
      padding: 0.5rem;
      position: relative;
      z-index: 3;

      &:last-child {
        border: none;
      }

      strong {
        flex: 1;
        max-width: 40%;
        text-transform: uppercase;
        font-family: $font-header;
        font-weight: 300;
        font-size: 0.86rem;
      }
      span {
        flex: 1;
        max-width: 60%;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  &__details {
    padding: 4rem 3rem 2rem 4rem;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      padding: 2rem 0;
    }

    &-item {
      flex: 1 1 auto;
      max-width: 50%;
      padding: 0 4rem 2rem 0;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 2rem;
      }

      h3 {
        margin: 0 0 2rem;
        padding: 0;
        font-family: $font-bold;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 400;
        position: relative;
      }

      p {
        margin: 0;
        padding: 0;
        line-height: 1.6;
        font-size: 1rem;
      }

      p + p {
        margin-top: 1rem;
      }

      &:nth-child(3) {
        padding: 4rem;
        margin: 2rem 4rem 0 -4rem;
        position: relative;

        @media screen and (max-width: 768px) {
          margin: 0;
          padding: 2rem;
        }

        &::after {
          content: "";
          background-color: $white;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          left: 0rem;
          top: 0rem;
        }

        &::before {
          content: "";
          background-color: $darkest;
          width: 60%;
          height: 40%;
          position: absolute;
          z-index: 1;
          left: -1.5rem;
          bottom: -2.5rem;
        }

        h3 {
          font-size: 1.7rem;
          padding: 0 0 1rem;
          margin: 0 0 2rem;
          color: $darkest;
          position: relative;
          z-index: 4;

          &:after {
            content: "";
            width: 3rem;
            height: 3px;
            background-color: $darkest;
            position: absolute;
            top: 100%;
            left: 0;
          }
        }
      }

      ol {
        margin: 1rem 0;
        padding: 0 1rem 0 1.2rem;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-family;
        line-height: 1.6;
        li {
          padding: 0 0 0.5rem;
        }
      }

      ul {
        margin: 1rem 0;
        list-style-type: square;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-family;
        line-height: 1.3;
        li {
          padding: 0 0 1rem;
        }
      }
    }

    &-desc {
      position: relative;
      z-index: 3;
    }
  }

&__kpis {
  flex: 1;
  max-width: calc(50% + 1rem);
  padding: 2rem 0 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      width: 100%;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 30%;

    & + & {
      margin-left: 3rem;
    }

    @media screen and (max-width: 768px) {
      max-width: 80%;
      width: 80%;
      flex-direction: row;
      margin: 2rem 3rem 0;
    }

    &-value {
      flex: auto;
      width: 9rem;
      height: 9rem;
      border: 7px solid $darkest;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: 2.4rem;
      font-family: $font-bold;
      font-weight: 400;
      color: $darkest;
      order: 0;
      z-index: 2;
      background-color: $bg-color;

      @media screen and (max-width: 768px) {
        max-width: 9rem;
      }

      strong {
        font-size: 1.6rem;
        font-weight: 600;
      }
      small {
        font-size: 1.2rem;
        font-weight: 600;
        padding-top: 0.4rem;
      }

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        height: 2px;
        background-color: $darkest;
        width: 10rem;
        z-index: 1;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    &:last-child ::after {
      display: none;
    }

    &-label {
      flex: 1;
      font-size: 1.1rem;
      padding: 2rem 0 0;
      font-family: $font-medium;
      font-weight: 400;
      color: $darkest;
      order: 1;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding: 0 0 0 2rem;
        text-align: left;
      }
    }
  }
}
}
</style>
