<template>
  <section class="section section-inspirations">
    <ul class="section-inspirations__list" v-if="inspirations">
      <li class="section-inspirations__list-item"
        v-for="item in inspirations"
        :key="item.id">
        <div class="section-inspirations__list-item-photo">
          <router-link
            :to="`inspirations/${item.url}/`"
            class="section-inspirations__list-item-img">
            <img :src="item.photo.thumb" />
          </router-link>
        </div>
        <InspirationItem :item="item" />
      </li>
    </ul>
    <div class="section__description" v-if="section">
      <h4>{{ section.subtitle }}</h4>
      <h3>{{ section.title }}</h3>
      <div v-html="section.description_short" />
      <router-link
        to="/inspirations/"
        class="btn__cta btn__cta-more-dark">
        <span>Wszystkie inspiracje</span>
        <iconArrow class="btn__cta-arrow" />
      </router-link>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/styles/_theme.scss';
@import '@/styles/front-inspirations.scss';
</style>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/inspirations'
import iconArrow from '@/assets/icon-arrow.vue'
import InspirationItem from '@/components/InspirationItem.vue'

export default {
  components: {
    InspirationItem,
    iconArrow
  },

  computed: {
    ...mapState({
      section: state => state.inspirations.contentPage,
      inspirations: state => state.inspirations.inspirations.slice(0,2)
    }),
  },

  mounted: function () {
    this.$store.state.OnAir.btnWhite = false;

    if (!this.inspirations.length) {
      this.$store.dispatch(
        `inspirations/${actionNames.LOAD_PAGE_CONTENT}`, '/inspirations/'
      )
    }
  }
}
</script>
